import { memo } from 'react';
import { TProductCardVariant } from '@insuma/mpp-ui/components/product-card';
import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { FIRST_LOAD_CATALOG_CAROUSEL_SKELETON } from 'core/constants/skeletons.constants';
import { ProductCardsSkeleton } from '../product-cards-skeleton';

import './carousel-skeleton.scss';

interface ICarouselSkeletonProps {
  ariaLabel?: string;
  variant?: TProductCardVariant;
}

const CarouselSkeleton = ({ ariaLabel = 'cargando productos', variant = 'default' }: ICarouselSkeletonProps) => {
  const css = useCSS('carousel-skeleton');

  return (
    <div className={css()} role="alert" aria-live="polite" aria-label={ariaLabel}>
      <Skeleton />
      <Skeleton
        style={{
          width: '14.4rem',
          height: '2rem',
        }}
        className={css('title')}
      />

      <div className={css('items')}>
        {[...Array(FIRST_LOAD_CATALOG_CAROUSEL_SKELETON)].map((_, index) => (
          <ProductCardsSkeleton key={`product-skeleton-${index}`} variant={variant} />
        ))}
      </div>
    </div>
  );
};

export default memo(CarouselSkeleton);
