import { createSelector } from '@reduxjs/toolkit';
import { errorSelector, loadingSelector, sourceNameSelector } from './core.selectors';

export const hasSelfErrorSelector = createSelector(errorSelector, sourceNameSelector, (error, sourceName) =>
  Boolean(error[sourceName]),
);

export const hasSelfLoadingSelector = createSelector(
  loadingSelector,
  sourceNameSelector,
  (loading, sourceName) => loading[sourceName],
);

export const isFetchingSelector = createSelector(
  loadingSelector,
  loading => loading.global || loading.alicorpCart || loading.alliedCart || loading.generalCart,
);
