import React, { createContext, ReactNode, useMemo } from 'react';
import { IScreen } from 'core/model/interfaces/screen.interface';
import useWindowSize from 'shared/hooks/use-window-size';

// TODO: improve isMobile isDesktop isTablet, considering also screen size
export const ScreenContext = createContext<IScreen>({
  height: 0,
  width: 0,
  isDesktop: false,
  isMobile: false,
  isTablet: false,
});
export const useScreen = () => React.useContext(ScreenContext);

export interface IScreenProviderProps {
  children: ReactNode;
}

export const ScreenProvider = ({ children }: IScreenProviderProps) => {
  const windowSize = useWindowSize();
  const screenContextValue = useMemo(() => ({ ...windowSize }), [windowSize]);

  return <ScreenContext.Provider value={screenContextValue}>{children}</ScreenContext.Provider>;
};
