import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { ordersInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const ordersSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: ordersInitialState,
  reducers,
  extraReducers,
});

const { reducer: ordersReducer } = ordersSlice;

export const { clearOrders } = ordersSlice.actions;

export default ordersReducer;
