import { createContext, ReactNode, useContext, useMemo } from 'react';
import type { ICarouselProductsProps } from './carousel-products';

export type TCarouselProductContextProps = Omit<ICarouselProductsProps, 'children'>;

interface ICarouselProductsComponentContextProps extends TCarouselProductContextProps {
  children: ReactNode;
}
export const CarouselProductContext = createContext<TCarouselProductContextProps>({
  products: [],
});

export const CarouselProductComponentContext = ({
  children,
  variant,
  products,
  title,
  shouldSeeMore,
}: ICarouselProductsComponentContextProps) => {
  const contextValue = useMemo(
    () => ({ variant, products, title, shouldSeeMore }),
    [variant, products, title, shouldSeeMore],
  );

  return <CarouselProductContext.Provider value={contextValue}>{children}</CarouselProductContext.Provider>;
};

export const useCarouselProductContext = () => {
  const context = useContext(CarouselProductContext);

  if (!context) {
    throw new Error('useCarouselProductContext must be used within a CarouselProductContext');
  }

  return context;
};
