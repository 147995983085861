import { CATALOGUE_SORT_OPTIONS } from 'core/constants/catalogue.constants';
import { usePaginatedProducts } from 'core/hooks/products/use-paginated-products';

export const useOnlyProductPromotions = () => {
  const { paginatedProducts, loading, lastBookElementRef } = usePaginatedProducts({
    orderBy: CATALOGUE_SORT_OPTIONS[0],
    hasPromotion: true,
  });

  return {
    paginatedProducts,
    loading,
    lastBookElementRef,
  };
};
