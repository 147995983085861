import { useMemo } from 'react';
import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import { useSuggestions } from './use-suggestions';

export const useBannerMessage = () => {
  const { suggestions } = useSuggestions(ESuggestedProductViewId.fixedBanner, false);
  const messageBanner = useMemo(() => (suggestions.length > 0 ? suggestions[0].title : ''), [suggestions]);

  return { messageBanner };
};
