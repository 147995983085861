import { useCallback } from 'react';
import { IBusiness } from 'core/model/interfaces/business.interface';
import { setAddress } from 'core/store/checkout';
import { checkoutAddressSelector } from 'core/store/checkout/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';

export const useSelectedAddress = () => {
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector(checkoutAddressSelector);

  const setSelectedAddress = useCallback(
    (address: IBusiness) => {
      dispatch(setAddress(address));
    },
    [dispatch],
  );

  return {
    selectedAddress,
    setSelectedAddress,
  };
};
