import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { IAmount } from 'core/model/interfaces/amount.interface';
import { IShoppingCartGroup, IShoppingCartGroups } from 'core/model/interfaces/shopping-cart.interface';
import { ICartState, IErrorCart } from './types';

export const SLICE_NAMESPACE = 'cart';

export const EMPTY_SHOPPING_CART_AMOUNTS: IAmount = { base: 0, final: 0, igv: 0, perception: 0, price: 0, discount: 0 };
export const EMPTY_SHOPPING_CART_GROUP: IShoppingCartGroup = {
  products: [],
  minimumSaleAmount: 0,
  amounts: EMPTY_SHOPPING_CART_AMOUNTS,
};
export const EMPTY_SHOPPING_CART_GROUPS: IShoppingCartGroups = {
  alliedItems: EMPTY_SHOPPING_CART_GROUP,
  alicorpItems: EMPTY_SHOPPING_CART_GROUP,
  generalItems: EMPTY_SHOPPING_CART_GROUP,
};
export const CART_ERRORS_INITIAL_STATE: IErrorCart = { userValidation: null, getData: null };

export const cartInitialState: ICartState = {
  itemsGroups: EMPTY_SHOPPING_CART_GROUPS,
  amounts: EMPTY_SHOPPING_CART_AMOUNTS,
  cartLoading: false,
  summaryLoading: false,
  errors: CART_ERRORS_INITIAL_STATE,
  success: false,
  currency: DEFAULT_CURRENCY,
  productsLoading: [],
  deletedProducts: [],
  areSuggestedProductsExpanded: false,
};
