import axios from 'axios';
import { SIGNAL_ABORTED_CODE } from 'core/constants/shopping-cart.constants';
import { EServicesErrorCode } from 'core/model/enums/error-code.enum';
import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { loadCreditLimitsAction } from 'core/store/credits';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { ICartState, IChangeProductActionParams } from '../types';

export const changeProductAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/changeProductAction`,
  async (payload: IChangeProductActionParams, { getState, dispatch, rejectWithValue, signal }) => {
    const { checkout } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);

    const abortController = new AbortController();
    signal.addEventListener('abort', () => {
      abortController.abort();
    });
    try {
      await apiService.shoppingCart.putShoppingCart(payload, customerId, abortController.signal);
    } catch (error) {
      if (signal.aborted) return rejectWithValue({ code: SIGNAL_ABORTED_CODE, message: 'Petición cancelada' });
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      if (error.response?.data?.code === EServicesErrorCode.CART_DEBT_BLOCKED) {
        dispatch(loadCreditLimitsAction());
      }
    }
  },
);

export const changeProductReducer: TSliceExtraReducer<ICartState> = builder => {
  builder
    .addCase(changeProductAction.pending, (state, { meta }) => {
      state.productsLoading = [...state.productsLoading, meta.arg.sku];
      state.success = false;
    })
    .addCase(changeProductAction.fulfilled, (state, { meta }) => {
      state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg.sku);
      state.success = true;
    })
    .addCase(changeProductAction.rejected, (state, { payload, meta }) => {
      if (payload && payload.code === SIGNAL_ABORTED_CODE) {
        state.success = false;
        state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg.sku);
      }
    });

  return builder;
};
