export enum EZoneEntryMenu {
  SUGGESTED_PRODUCTS = 'productos sugeridos',
  LATERAL_BAR = 'barra lateral',
  MAIN_MENU = 'menu principal',
  SEE_MORE = 'ver mas',
}
export enum EProductOrigin {
  HOME = 'Home',
  DETAIL_PRODUCT = 'Detalle del producto',
  CATEGORY_LIST = 'Pagina categoría',
  SUBCATEGORY_LIST = 'Pagina subcategoría',
  PROMOTIONS_PRODUCTS_HOME = 'Pagina de Promociones',
  SEARCH_RESULTS = 'Resultados de búsqueda',
  HIGHLIGHTED_PRODUCTS_HOME = 'Carrusel de destacados',
  HIGHLIGHTED_PRODUCTS_PAGE = '[Pagina de destacados] Carrusel de destacados',
  SUGGESTED_PRODUCTS_HOME = '[Producto Sugerido] Home',
  SUGGESTED_PRODUCTS_CART = '[producto sugerido] Carrito',
  SUGGESTED_PRODUCTS_GENERAL = '[Producto Sugerido] General',
  SHOPPING_CART = 'Carrito de compras',
}
