import { useState } from 'react';
import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';
import useWindowSize from 'shared/hooks/use-window-size';

import './entries-skeleton.scss';

const getRandomWidth = () => {
  const min = 60;
  const max = 120;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const EntriesSkeleton = () => {
  const css = useCSS('entries-skeleton');
  const { width } = useWindowSize();
  const [nSkeletons] = useState(Math.floor(Math.min(width, 1280) / 142));
  const [skeletonsWidths] = useState<Array<number>>(
    Array(nSkeletons)
      .fill(0)
      .map(() => getRandomWidth()),
  );

  return (
    <div className={css()} role="alert" aria-label="cargando categorías" aria-busy="true" aria-live="polite">
      <div className={css('wrapper')}>
        {skeletonsWidths.map((width, index) => (
          <Skeleton key={index} style={{ width }} />
        ))}
      </div>
    </div>
  );
};
