import { createSelector } from '@reduxjs/toolkit';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import {
  authCustomerIsHybridSelector,
  authGetCustomerSelector,
  authGetCustomerSource,
} from 'core/store/auth/selectors';
import { extractCurrency } from '../checkout-state.utils';
import { checkoutDetailSelector, paymentMethodSelector, sourceNameSelector } from './core.selectors';

export const checkoutCurrencySelector = createSelector(
  authCustomerIsHybridSelector,
  checkoutDetailSelector,
  (isHybrid, detail) => {
    const toSource = isHybrid ? detail.alicorpCart : detail.generalCart;
    return extractCurrency(toSource);
  },
);

export const paymentMethodItemsSelector = createSelector(
  paymentMethodSelector,
  paymentMethod => paymentMethod.generalCart,
);

export const paymentMethodsBySourceSelector = createSelector(authGetCustomerSelector, customer => {
  const { dex, apudex } = customer;
  return { dex: dex?.paymentMethods, apudex: apudex?.paymentMethods };
});

export const checkoutCurrentPaymentMethodSelector = createSelector(
  paymentMethodSelector,
  sourceNameSelector,
  (paymentMethod, source) => paymentMethod[source],
);

export const paymentMethodFirstStepSelector = createSelector(
  authGetCustomerSource,
  paymentMethodsBySourceSelector,
  (source, { apudex, dex }) => (source === ECustomerSource.DEX ? dex : apudex),
);
