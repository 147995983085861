import { IProduct } from 'core/model/interfaces/product.interface';
import type { TSliceExtraReducer } from 'core/store';
import { authGetCustomerSource } from 'core/store/auth/selectors';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { dispatchGetSuggestedProductsForAllLocations } from 'core/store/suggested-products/utils';
import { removeProductOptimisticallyByGroups } from '../cart-state.utils';
import { EMPTY_SHOPPING_CART_GROUPS, SLICE_NAMESPACE } from '../constants';
import { ICartState } from '../types';

export const optimisticRemoveProductAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/optimisticRemoveProduct`,
  async (payload: IProduct, { getState, dispatch }) => {
    const { sku, sourceId } = payload;
    const state = getState();
    const { cart } = state;
    const customerSource = dispatch(() => authGetCustomerSource(state));
    const updatedItemGroups = removeProductOptimisticallyByGroups(customerSource, cart, sku, sourceId);
    dispatchGetSuggestedProductsForAllLocations(dispatch);
    return { ...cart, itemsGroups: updatedItemGroups };
  },
);

export const optimisticRemoveProductReducer: TSliceExtraReducer<ICartState> = builder => {
  builder.addCase(optimisticRemoveProductAction.fulfilled, (state, { payload }) => {
    state.itemsGroups = payload.itemsGroups || EMPTY_SHOPPING_CART_GROUPS;
    state.amounts = payload.amounts;
  });

  return builder;
};
