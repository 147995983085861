import { type ReactNode } from 'react';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { useCarouselProductContext } from '../../carousel-products.context';

import './carousel-products-header.scss';

export interface ICarouselProductHeaderProps {
  totalItems: number;
  children?: ReactNode;
}

export const CarouselProductsHeader = ({ totalItems = 0, children }: ICarouselProductHeaderProps) => {
  const { variant, title, shouldSeeMore } = useCarouselProductContext();
  const css = useCSS('carousel-products-header');
  const isSmall = variant === 'simplified';

  return (
    <div className={css({ small: isSmall })}>
      <div className={css('header-content')}>
        <h3 className={css('title', { small: isSmall })} aria-label={title}>
          {title}
        </h3>
        <div className={css('amount')}>{totalItems} Productos</div>
      </div>
      {shouldSeeMore && children}
    </div>
  );
};
