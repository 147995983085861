import { Icon } from '@insuma/mpp-ui/components/icon';
import { Skeleton, SkeletonPlaceHolder } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { isCartLoadingSelector, totalProductsSelector } from 'core/store/cart/selectors';
import { useAppSelector } from 'core/store/store.utils';
import { accessibleOnClick } from 'shared/utils/a11y.utils';

import './header-cart-button.scss';

export interface IHeaderCartButtonProps {
  onToggleCart: React.MouseEventHandler<HTMLButtonElement>;
}

export const HeaderCartButton = ({ onToggleCart }: IHeaderCartButtonProps) => {
  const isCartLoading = useAppSelector(isCartLoadingSelector);
  const totalProducts: number = useAppSelector(totalProductsSelector);
  const css = useCSS('header-cart-button');

  return (
    <div className={css('cart')}>
      <div {...accessibleOnClick(onToggleCart)} className={css('block')}>
        <button type="button" className={css('badge')} disabled={isCartLoading} aria-label="Abrir carrito">
          <SkeletonPlaceHolder loading={isCartLoading} skeleton={<Skeleton className={css('counter-skeleton')} />}>
            <div className={css('counter')}>{totalProducts}</div>
          </SkeletonPlaceHolder>
          <Icon name="shopping-cart" variant="primary" />
        </button>
      </div>
    </div>
  );
};
