import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import { ICheckoutCartDetailDTO, ICheckoutCartDetailParams } from './checkout.dto';

export const checkoutData = {
  getCartDetail: async (customerId: number, params?: ICheckoutCartDetailParams): Promise<ICheckoutCartDetailDTO> => {
    const { data } = await https.get<IServiceSuccess<ICheckoutCartDetailDTO>>(
      '/gtm-order-management/v1/checkout-cart',
      {
        params,
        headers: { 'customer-id': customerId },
      },
    );
    return data;
  },
};
