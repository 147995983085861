import { customAlphabet } from 'nanoid';
import { TRACEPARENT_FLAGS_ID, TRACEPARENT_VERSION_ID } from 'core/constants/general.constants';

export const generateTraceParent = () => {
  const nanoid = customAlphabet('0123456789abcdef', 10);
  const version = TRACEPARENT_VERSION_ID;
  const traceId = nanoid(32);
  const id = nanoid(16);
  const flags = TRACEPARENT_FLAGS_ID;

  return `${version}-${traceId}-${id}-${flags}`;
};
