import { ReactNode } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { AuthenticatedLayout } from './components/authenticated-layout';
import { UnauthenticatedLayout } from './components/unauthenticated-layout';

const MainLayout = ({ children }: { children: ReactNode }) => {
  const isLoggedIn = useIsAuthenticated();

  const Layout = isLoggedIn ? AuthenticatedLayout : UnauthenticatedLayout;

  return <Layout>{children}</Layout>;
};

export default MainLayout;
