import { ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { LazyMenuEntries } from 'screens/screens.lazy';
import { EntriesSkeleton } from 'shared/components/navigation-ui/menu-entries/components/entries-skeleton';
import { MenuSubcategoriesDrawer } from 'shared/components/navigation-ui/menu-subcategories-drawer';
import { SuspensePage } from 'shared/components/suspense/supense-page';

export const MenuLayout = ({ children }: { children?: ReactNode }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {isAuthenticated && (
        <Suspense fallback={<EntriesSkeleton />}>
          <LazyMenuEntries />
        </Suspense>
      )}
      {isAuthenticated && <MenuSubcategoriesDrawer />}
      <SuspensePage>{children ?? <Outlet />}</SuspensePage>
    </>
  );
};
