import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import { IGetCustomizationElementsResponse } from './customization.dto';

export const baseUrl = '/gtm-static-management/v1/customization';

export const customizationData = {
  getCustomizationElements: async (customerId: number) => {
    const { data } = await https.get<IServiceSuccess<IGetCustomizationElementsResponse>>(`${baseUrl}/banners`, {
      headers: { 'customer-id': customerId },
    });
    return data;
  },
};
