import { useNavigate } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { getFeaturedProductsSelector } from 'core/store/highlighted-products/selectors';
import { useAppSelector } from 'core/store/store.utils';
import { CarouselProducts } from 'shared/components/products/carousel-products';
import gtmEvents from 'shared/utils/gtm-events';

const origin = EProductOrigin.HIGHLIGHTED_PRODUCTS_HOME;

export const CarouselHighlightedProducts = () => {
  const navigate = useNavigate();
  const props = useAppSelector(getFeaturedProductsSelector);
  const { title, loading, totalProducts } = props;

  const handleSeeAll = () => navigate(Path.HIGHLIGHTED);

  const gtmRegisterListProducts = (visibleProducts: Array<IProduct>) => {
    gtmEvents.triggerViewProductListEvent({
      products: visibleProducts,
      origin,
    });
  };

  return (
    <CarouselProducts {...props} ariaLabel={loading ? 'Cargando productos destacados' : ''}>
      <CarouselProducts.Header totalItems={totalProducts}>
        <CarouselProducts.SeeMoreButton onClick={handleSeeAll} />
      </CarouselProducts.Header>
      <CarouselProducts.Body origin={origin} listName={title} onViewCarousel={gtmRegisterListProducts}>
        <CarouselProducts.SeeMoreCard onSeeAllClick={handleSeeAll} />
      </CarouselProducts.Body>
    </CarouselProducts>
  );
};
