import { generateTraceParent } from 'shared/utils/trace-parent.utils';
import { MSAL_CLIENT_ID } from './general.constants';

const MSAL_SCOPES = [MSAL_CLIENT_ID, 'openid', 'offline_access'];

export const DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS = {
  extraQueryParameters: {
    traceparent: generateTraceParent(),
    ui_locales: 'es',
  },
  scopes: MSAL_SCOPES,
};

export const LOCAL_STORAGE_TOKEN_KEY = 'msal_auth_jwt';
