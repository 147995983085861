import { IVisitorInformation, IVisitorProvider } from 'core/model/interfaces/visitor.interface';

export class VisitorGeneric {
  private provider: IVisitorProvider;

  constructor(provider: IVisitorProvider) {
    this.provider = provider;
  }

  public async getInformation(): Promise<IVisitorInformation> {
    return this.provider.getInformation();
  }
}
