export const limitWords = (text: string, maxWords: number) => {
  const numberWords = text.split(' ').length;
  if (numberWords > maxWords)
    return `${text
      .split(' ')
      .slice(0, maxWords + 1)
      .join(' ')}...`;
  return text;
};

export const limitCharacters = (text: string, maxCharacters: number) => {
  const numberWords = text.length;
  if (numberWords > maxCharacters)
    return `${text
      .split('')
      .slice(0, maxCharacters + 1)
      .join('')}...`;
  return text;
};
