import { useEffect } from 'react';
import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';
import { getSuggestedProducts } from 'core/store/suggested-products';
import { getSuggestionsByViewIdSelectors } from 'core/store/suggested-products/selectors';

export const useSuggestions = (locationId: ESuggestedProductViewId, autoLoad = true) => {
  const dispatch = useAppDispatch();
  const isCartLoading = useAppSelector(state => state.cart.cartLoading);
  const { isLoading: areSuggestionsLoading, suggestions } = useAppSelector(state =>
    getSuggestionsByViewIdSelectors(state, locationId),
  );

  const shouldSuggestionsAppearAsLoading = isCartLoading || areSuggestionsLoading;

  useEffect(() => {
    if (autoLoad) {
      dispatch(getSuggestedProducts(locationId));
    }
  }, [dispatch, locationId, autoLoad]);

  return {
    suggestions,
    areSuggestionsLoading,
    shouldSuggestionsAppearAsLoading,
  };
};
