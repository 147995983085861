import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { creditsInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const creditsSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: creditsInitialState,
  reducers,
  extraReducers,
});

const { reducer: creditsReducer } = creditsSlice;

export default creditsReducer;
