import { useCallback } from 'react';
import { ECartActions } from 'core/model/enums/shopping-cart.enum';
import { getCartAction } from 'core/store/cart';
import { cartSelector } from 'core/store/cart/selectors';
import { toogleCart, toogleMenu } from 'core/store/layout';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';

const useAppNavigation = () => {
  const dispatch = useAppDispatch();
  const { cartLoading } = useAppSelector(cartSelector);

  const onToggleMenu = useCallback(() => {
    dispatch(toogleMenu(true));
  }, [dispatch]);

  const onToggleCart = useCallback(() => {
    dispatch(toogleCart(true));
    if (!cartLoading) dispatch(getCartAction({ cartAction: ECartActions.REFRESH, shouldRetry: true }));
  }, [dispatch, cartLoading]);

  return {
    onToggleMenu,
    onToggleCart,
  };
};

export default useAppNavigation;
