import { Icon } from '@insuma/mpp-ui/components/icon';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { useCarouselProductContext } from '../../carousel-products.context';

import './see-more-card.scss';

export interface ISeeMoreCardProps {
  onSeeAllClick: () => void;
}

export const SeeMoreCard = ({ onSeeAllClick }: ISeeMoreCardProps) => {
  const { variant, title } = useCarouselProductContext();
  const isSmall = variant === 'simplified';
  const css = useCSS('see-more-card');

  return (
    <article className={css({ small: isSmall })}>
      <div className={css('content')}>
        <h4 className={css('title')}>{title}</h4>
        <button onClick={onSeeAllClick} className={css('button')} type="button">
          Ver más <Icon name="caret-right" size="sm" />
        </button>
      </div>
    </article>
  );
};
