import { authGetCustomerSource } from 'core/store/auth/selectors';
import { allProductsByUserSourceSelector } from 'core/store/cart/selectors';
import { canBuyByDistributorSelector } from 'core/store/credits/selectors';
import { useAppSelector } from 'core/store/store.utils';

export const useCarouselProductsData = () => {
  const cartProducts = useAppSelector(allProductsByUserSourceSelector);
  const customerSource = useAppSelector(authGetCustomerSource);
  const { canPurchaseApudex, canPurchaseDex } = useAppSelector(canBuyByDistributorSelector);

  return { cartProducts, customerSource, canPurchaseApudex, canPurchaseDex };
};
