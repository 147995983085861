import { useLocation, useNavigate } from 'react-router-dom';
import { ImageRoutes } from 'core/constants/image-routes';
import { Path } from 'core/constants/path.constants';
import { HeaderWrapper } from '../header-wrapper';

import './unauthenticated-header.scss';

export const UnauthenticatedHeader = () => {
  const navigate = useNavigate();

  const { pathname: path } = useLocation();

  const handleClickLogo = (): void => {
    if (path === Path.HOME) {
      document.location.reload();
      return;
    }
    navigate(Path.HOME);
  };

  return (
    <HeaderWrapper>
      <div className="unauthenticated-header__icons">
        <button type="button" className="unauthenticated-header__logo" onClick={handleClickLogo}>
          <img className="unauthenticated-header__image" src={ImageRoutes.LOGO_MAIN_HEADER} alt="Logo Insuma" />
        </button>
      </div>
    </HeaderWrapper>
  );
};
