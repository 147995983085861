import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { ICustomizationState } from '../types';

export const getCustomizationElementsAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/getCustomizationElements`,
  (_, { getState }) => {
    const { checkout } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);
    return apiService.customization.getCustomizationElements(customerId);
  },
);

export const getCustomizationElementsReducer: TSliceExtraReducer<ICustomizationState> = builder => {
  builder
    .addCase(getCustomizationElementsAction.pending, state => {
      state.banners = [];
      state.shortcuts = [];
      state.isLoading = true;
    })
    .addCase(getCustomizationElementsAction.rejected, state => {
      state.banners = [];
      state.shortcuts = [];
      state.isLoading = false;
    })
    .addCase(getCustomizationElementsAction.fulfilled, (state, { payload }) => {
      state.banners = payload.carrousel;
      state.shortcuts = payload.shortcuts;
      state.isLoading = false;
    });

  return builder;
};
