import { ECheckoutError } from 'core/model/enums/checkout.enum';

export const DELIVERY_CONTACT_NUMBER = '949449467';

export const PAYMENT_METHOD_NAME = {
  cash: 'Efectivo',
  transfer: 'Transferencia',
  credit: 'Credito',
};

export const TIME_MS_TO_RESTORE_SCROLL = 250;

export const NUMBER_OF_POST_ORDER_API_RETRIES = 2;
export const POST_ORDER_API_EXPONENTIAL_DELAY = 3000;

export const CHECKOUT_ERROR_BEHAVIOURS = {
  reviewMyOrdersBecauseOfDisconnectionError: {
    code: ECheckoutError.NETWORK_OR_TIMEOUT_ERROR,
    message: 'Se ha perdido la conexión, por favor dirígete Mis pedidos y confirma si tu pedido se ha generado.',
  },
};
