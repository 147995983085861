/** Use when you want to be sure that the objects are equal but it costs in performance */
export const isObjectDeepEqual = <T extends object | null>(object1: T, object2: T): boolean => {
  if (!object1 || !object2) {
    return false;
  }

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of objKeys1) {
    const value1 = object1[key as keyof object] as T;
    const value2 = object2[key as keyof object] as T;

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isObjectDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

/** Use when you are completely sure that the object 1 and 2 have the same order properties */
export const isObjectSimpleEqual = <T extends object | null>(object1: T, object2: T): boolean =>
  JSON.stringify(object1) === JSON.stringify(object2);

export const isObject = <T extends object | null | undefined>(object: T) =>
  object != null && typeof object === 'object';

export const cloneDeepSimple = <T extends object>(object: T): T => JSON.parse(JSON.stringify(object));
