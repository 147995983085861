import { ICatalogSortOption } from 'core/model/interfaces/catalogues.interface';

export const CATALOGUE_SORT_OPTIONS: Array<ICatalogSortOption> = [
  {
    id: 'name',
    content: 'A-Z',
    value: 'hasStock:desc,name:asc',
  },
  {
    id: 'lowerPrice',
    content: 'Menor precio',
    value: 'hasStock:desc,price:asc',
  },
  {
    id: 'higherPrice',
    content: 'Mayor precio',
    value: 'hasStock:desc,price:desc',
  },
];

export const DEFAULT_CATALOG_PAGE = 1;
export const DEFAULT_CATALOG_ITEMS_PER_PAGE = 12;
export const PRODUCT_COUNTER_CLICKING_DELAY = 500;
