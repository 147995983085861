import { EPromotionDetailTag, EPromotionTag } from 'core/model/enums/promotion.enum';
import {
  IProductDetail,
  IUnitMeasure,
  IUnitMeasureDetail,
  TGenericUnitMeasure,
} from 'core/model/interfaces/product.interface';
import {
  IComboPromotion,
  IDetailedPromotion,
  ISimplePromotion,
  ITieredPromotion,
} from 'core/model/interfaces/promotion.interface';
import { updateSelectedUnitMeasure } from 'shared/utils/products.utils';

export const changeSelectedUnitMeasure = <T extends { unitMeasures: Array<TGenericUnitMeasure> }>(
  productDetail: T,
  newUnitMeasureCode: string,
): T => ({
  ...productDetail,
  unitMeasures: productDetail.unitMeasures.map(um => updateSelectedUnitMeasure(um, newUnitMeasureCode)),
});

// TODO: Refactor esta funcion para que los parametros no sean null
export const updateProductDetailFromCart = (
  originalProductDetail: IProductDetail | null,
  unitMeasureCodeInCart: string | null,
  isAddedAsPackage: boolean,
): IProductDetail | undefined => {
  if (!originalProductDetail) {
    return undefined;
  }
  const productDetail = { ...originalProductDetail };

  if (isAddedAsPackage && unitMeasureCodeInCart) {
    productDetail.unitMeasures = productDetail.unitMeasures.map(um =>
      updateSelectedUnitMeasure(um, unitMeasureCodeInCart),
    );
  }

  return productDetail;
};

export const unitMeasureDetailToUnitMeasure = (unitMeasures: Array<IUnitMeasureDetail>): Array<IUnitMeasure> =>
  unitMeasures.map(um => ({
    ...um,
    promotions: detailedToListPromotions(um.promotions),
  }));

export const detailedToListPromotions = (detailedPromotions: IDetailedPromotion): Array<{ tag: EPromotionTag }> => {
  const entities = new Set<EPromotionTag>();
  const { simple, tiered, combo } = detailedPromotions;

  const addPromotions = (
    promotions: Array<ISimplePromotion | ITieredPromotion | IComboPromotion>,
    discountType: EPromotionTag,
    bonusType: EPromotionTag,
  ) => {
    for (let i = 0; i < promotions.length; i += 1) {
      const { tag } = promotions[i];

      if (entities.has(discountType) && entities.has(bonusType)) {
        break;
      }
      if (tag === EPromotionDetailTag.DISCOUNT) {
        entities.add(discountType);
      }
      if (tag === EPromotionDetailTag.BONUS) {
        entities.add(bonusType);
      }
    }
  };

  addPromotions(simple, EPromotionTag.SIMPLE_DISCOUNT, EPromotionTag.SIMPLE_BONUS);
  addPromotions(tiered, EPromotionTag.TIERED_DISCOUNT, EPromotionTag.TIERED_BONUS);
  addPromotions(combo, EPromotionTag.COMBO_DISCOUNT, EPromotionTag.COMBO_BONUS);

  return [...entities].map(tag => ({ tag }));
};
