import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { navigationInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const navigationSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: navigationInitialState,
  reducers,
  extraReducers,
});

const { reducer: navigationReducer } = navigationSlice;

export const { resetSelectedEntry, selectEntry, toggleSubcategoriesDrawer } = navigationSlice.actions;

export default navigationReducer;
