import { IHighlightedProductsState } from './types';

export const SLICE_NAMESPACE = 'highlighted-products';

export const highlightedProductsInitialState: IHighlightedProductsState = {
  featured: {
    title: '',
    products: [],
    loading: true,
    hasMoreProducts: true,
    totalProducts: 0,
  },
};
