import { EZoneEntryMenu } from 'core/model/enums/gtm.enum';
import { pushObjectDataLayer } from './gtm.events';

interface ITriggerSelectLateralMenuEvent {
  cta: string;
}

interface ITriggerEntryMenuEvent {
  zone: EZoneEntryMenu;
  category: string;
  subcategory: string;
}

export const triggerWhatsAppEvent = () => {
  const data = {
    event: 'virtualEventD1',
    tipoevento: 'interaccion',
    cta: 'contacta con nosotros - whatsapp',
    url_pagina: window.location.href,
  };
  pushObjectDataLayer(data, triggerWhatsAppEvent.name);
};

export const triggerSideMenuItemsEvent = ({ cta }: ITriggerSelectLateralMenuEvent) => {
  const data = {
    event: 'virtualEventD2',
    tipoevento: 'interaccion',
    zona: 'barra lateral',
    cta,
  };
  pushObjectDataLayer(data, triggerSideMenuItemsEvent.name);
};

export const triggerEntryMenuEvent = ({ zone, category, subcategory }: ITriggerEntryMenuEvent) => {
  const data = {
    event: 'virtualEventD3',
    tipoevento: 'interaccion',
    zona: zone,
    categoria_web: category,
    subcategoria_web: subcategory,
    cta: 'ingresar a categoria o subcategoria de productos',
  };
  pushObjectDataLayer(data, triggerEntryMenuEvent.name);
};
