import { createSlice } from '@reduxjs/toolkit';
import { layoutInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const layoutSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: layoutInitialState,
  reducers,
});

export const { toogleCart, toogleMenu, setSearchQuery, setNotFound, toogleAddressModal } = layoutSlice.actions;

export default layoutSlice.reducer;
