import { catalogsService as catalog } from './catalogs/catalogs.service';
import { checkoutService as checkout } from './checkout/checkout.service';
import { customersService as customer } from './customers/customers.service';
import { customizationService as customization } from './customization/customization.service';
import { navigationService as navigation } from './navigation/navigation.service';
import { ordersService as orders } from './orders/orders.service';
import { promotionsService as promotion } from './promotions/promotions.service';
import { shoppingCartService as shoppingCart } from './shopping-cart/shopping-cart.service';
import { staticPageService as staticPage } from './static-page/static-page.service';
import { suggestedProductsService as suggestedProducts } from './suggested-products/suggested-products.service';

export const apiService = {
  catalog,
  customer,
  customization,
  navigation,
  orders,
  promotion,
  shoppingCart,
  staticPage,
  checkout,
  suggestedProducts,
};
