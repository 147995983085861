import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './header-cart-skeleton-button.scss';

export const HeaderCartSkeletonButton = () => {
  const css = useCSS('header-cart-skeleton-button');

  return (
    <div className={css()} role="alert" aria-label="Cargando botón de carrito">
      <Skeleton className={css('text')} />
      <Skeleton className={css('icon')} />
    </div>
  );
};
