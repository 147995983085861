import { useEffect, useRef, useState } from 'react';

import './moving-eye.scss';

export const MovingEye = () => {
  const dispatchEye1Ref = useRef<HTMLDivElement | null>(null);
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      const width = window.innerWidth;
      const x = e.clientX;
      setA((x / width) * 100);
      const height = window.innerHeight;
      const y = e.clientY;
      setB((y / height) * 100);
    };

    document.addEventListener('mousemove', listener);

    return () => {
      document.removeEventListener('mousemove', listener);
    };
  }, []);

  return (
    <div className="moving-eye-white">
      <div
        className="moving-eye-white__ball"
        style={{ left: ` ${a}%`, top: `${b}%`, transform: `translate( -${a}%, -${b}%)` }}
        ref={dispatchEye1Ref}
      />
    </div>
  );
};
