import { TRootState } from '../reducers';

export const getBannersSelector = (state: TRootState) => ({
  banners: state.customization.banners,
  isLoading: state.customization.isLoading,
});

export const getShortcutsSelector = (state: TRootState) => ({
  shortcuts: state.customization.shortcuts,
  isLoading: state.customization.isLoading,
});

export const hasBannersSelector = (state: TRootState) => state.customization.banners.length > 0;
