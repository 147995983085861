import { memo, type MemoExoticComponent, type ReactNode } from 'react';
import type { TProductCardVariant } from '@insuma/mpp-ui/components/product-card';
import { SkeletonPlaceHolder } from '@insuma/mpp-ui/components/skeleton';
import { IProduct } from 'core/model/interfaces/product.interface';
import { SeeMoreButton } from 'shared/components/see-more-button';
import { isObjectSimpleEqual } from 'shared/utils/object.utils';
import CarouselSkeleton from '../carousel-skeleton';
import { CarouselProductComponentContext } from './carousel-products.context';
import { CarouselProductsBody, CarouselProductsHeader, SeeMoreCard } from './components';

export interface ICarouselProductsProps {
  products: Array<IProduct>;
  title?: string;
  variant?: TProductCardVariant;
  children: ReactNode;
  loading?: boolean;
  ariaLabel?: string;
  shouldSeeMore?: boolean;
}

interface ICompoundCarouselProducts extends MemoExoticComponent<(props: ICarouselProductsProps) => JSX.Element> {
  Header: typeof CarouselProductsHeader;
  Body: typeof CarouselProductsBody;
  SeeMoreButton: typeof SeeMoreButton;
  SeeMoreCard: typeof SeeMoreCard;
}

/**
 * The `CarouselProducts` component renders a customizable, carousel-style list of product items.
 *
 * This component provides a structured layout for showcasing products, allowing users to scroll
 * through a collection with an optional "See More" feature. Designed for flexibility, it accepts
 * various subcomponents— Header, Body, SeeMoreButton, and SeeMoreCard to allow for a well-organized,
 * interactive carousel experience.
 *
 * ## Compound Components
 *
 * `CarouselProducts` comes with four primary subcomponents:
 *
 * - **Header**: A configurable section for the carousel header, often used to display a title and a total item count.
 * - **Body**: The main display area for product cards, supports dynamic loading or tracking functions.
 * - **SeeMoreButton**: A button element designed for extending the view, typically linking to a broader collection.
 * - **SeeMoreCard**: An alternative or additional component to the SeeMoreButton, allowing for a more interactive "See More" experience.
 *
 * @example
 *
 * ```tsx
 * return (
 *   <CarouselProducts products={products} title={title} shouldSeeMore={shouldShowSeeMore}>
 *     <CarouselProducts.Header totalItems={totalItems}>
 *       <CarouselProducts.SeeMoreButton onClick={handleSeeAll} text="View More" />
 *     </CarouselProducts.Header>
 *     <CarouselProducts.Body origin={origin} listName={listName} onViewCarousel={gtmRegisterListProducts}>
 *       <CarouselProducts.SeeMoreCard onSeeAllClick={handleSeeAll} />
 *     </CarouselProducts.Body>
 *   </CarouselProducts>
 * );
 * ```
 *
 * ## Memoization
 *
 * The `CarouselProducts` component is wrapped in `React.memo` with a shallow comparison function, `isObjectSimpleEqual`,
 * optimizing re-render performance by ensuring that the component only re-renders when necessary.
 *
 * @component
 */
const CarouselProducts = ({
  products,
  title = '',
  variant = 'default',
  shouldSeeMore = false,
  loading = false,
  ariaLabel = '',
  children,
}: ICarouselProductsProps) => (
  <CarouselProductComponentContext variant={variant} products={products} title={title} shouldSeeMore={shouldSeeMore}>
    <SkeletonPlaceHolder loading={loading} skeleton={<CarouselSkeleton ariaLabel={ariaLabel} />}>
      {products?.length !== 0 && <div role="region">{children}</div>}
    </SkeletonPlaceHolder>
  </CarouselProductComponentContext>
);

// const MemoizedCarouselProducts = memo(CarouselProducts) as ICompoundCarouselProducts;
const MemoizedCarouselProducts = memo(
  CarouselProducts,
  ({ children: _prevChildren, ...prevProps }, { children: _nextChildren, ...nextProps }) =>
    isObjectSimpleEqual(prevProps, nextProps),
) as ICompoundCarouselProducts;

MemoizedCarouselProducts.Header = CarouselProductsHeader;
MemoizedCarouselProducts.Body = CarouselProductsBody;
MemoizedCarouselProducts.SeeMoreButton = SeeMoreButton;
MemoizedCarouselProducts.SeeMoreCard = SeeMoreCard;

export { MemoizedCarouselProducts as CarouselProducts };
