import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { useOnlyProductPromotions } from 'screens/promotion-products/hooks/use-only-product-promotions';
import { CarouselProducts } from 'shared/components/products/carousel-products';
import gtmEvents from 'shared/utils/gtm-events';

export interface ICarouselCardPromotionsProps {
  isIdle: boolean;
}

const origin = EProductOrigin.PROMOTIONS_PRODUCTS_HOME;

export const CarouselCardPromotions = ({ isIdle }: ICarouselCardPromotionsProps) => {
  const { paginatedProducts: promotionProducts, loading: arePromotionsLoading } = useOnlyProductPromotions();
  const { pagination, products } = promotionProducts;
  const totalItems = pagination.totalItems || products.length;
  const seeMore = pagination.totalPages > 1;

  const navigate = useNavigate();
  const handleSeeAll = useCallback(() => navigate(Path.PROMOTIONS), [navigate]);

  const gtmRegisterListProducts = useCallback((visibleProducts: Array<IProduct>) => {
    gtmEvents.triggerViewProductListEvent({
      products: visibleProducts,
      origin,
    });
  }, []);

  return (
    <CarouselProducts
      title="Promociones para ti"
      shouldSeeMore={seeMore}
      products={promotionProducts?.products}
      loading={arePromotionsLoading && isIdle}
      ariaLabel={arePromotionsLoading ? 'cargando promociones' : ''}
    >
      <CarouselProducts.Header totalItems={totalItems}>
        <CarouselProducts.SeeMoreButton onClick={handleSeeAll} />
      </CarouselProducts.Header>
      <CarouselProducts.Body origin={origin} onViewCarousel={gtmRegisterListProducts}>
        <CarouselProducts.SeeMoreCard onSeeAllClick={handleSeeAll} />
      </CarouselProducts.Body>
    </CarouselProducts>
  );
};
