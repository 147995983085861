export const CONTACT_DATA = {
  EMAIL_CONTACT: 'servicioalcliente@insuma.com.pe',
  NUMBER_CONTACT: '945537324',
  MESSAGE_WSP: 'Hola, tengo unas dudas y consultas. ¿Podrías ayudarme? 🙂',
  SIGN_UP: 'https://www.insuma.com.pe/',
  FACEBOOK_CONTACT: 'https://www.facebook.com/InsumaPeru/',
  YOUTUBE_CONTACT: 'https://www.youtube.com/@insuma4852/featured',
  INSTAGRAM_CONTACT: 'https://www.instagram.com/insumaperu/',
};

export const COMPANY_DATA = {
  BUSINESSNAME: 'APUDEX S.A.C.',
  RUC: '20602893350',
  PHONE: '976712178',
  NAME: 'Jeremy Miranda',
  EMAIL: 'atencioncobranzas@insuma.com.pe',
};
