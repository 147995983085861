import { ReactNode } from 'react';
import { InView } from 'react-intersection-observer';
import { ProductCard } from '@insuma/mpp-ui/components/product-card';
import { SkeletonPlaceHolder } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { PRODUCT_COUNTER_CLICKING_DELAY } from 'core/constants/catalogue.constants';
import { ImageRoutes } from 'core/constants/image-routes';
import { useProductActions } from 'core/hooks/products/use-product-actions';
import { EProductOrigin } from 'core/model/enums/gtm.enum';
import type { IProduct } from 'core/model/interfaces/product.interface';
import CarouselSkeleton from 'shared/components/products/carousel-skeleton';
import { SlickSlider } from 'shared/components/slick-slider';
import { mapProductToCardProductData } from 'shared/utils/products.utils';
import { useCarouselProductContext } from '../../carousel-products.context';
import { useCarouselProductsData } from '../../use-carousel-products-data.hooks';

import './carousel-products-body.scss';

export interface ICarouselProductBodyProps {
  listName?: string;
  origin: EProductOrigin;
  onViewCarousel?: (products: Array<IProduct>) => void;
  suggestedProductType?: string;
  children?: ReactNode;
}

export const CarouselProductsBody = ({
  listName = 'null',
  origin,
  suggestedProductType,
  children,
  onViewCarousel,
}: ICarouselProductBodyProps) => {
  const css = useCSS('carousel-products-body');
  const { canPurchaseApudex, canPurchaseDex, cartProducts, customerSource } = useCarouselProductsData();
  const { variant = 'default', products, title, shouldSeeMore } = useCarouselProductContext();
  const { onAbort, onChangeCounterQuantity, onDetailProduct } = useProductActions({
    listName,
    origin,
  });

  const renderProductCards = () => {
    const productCards = products.map((item, index) => {
      const cartProduct = cartProducts?.find(p => p.sku === item.sku);
      const quantity = cartProduct?.quantity ?? 0;
      const {
        product: cardProductData,
        unitMeasureCode,
        tags,
        infoLine,
        infoLabel,
      } = mapProductToCardProductData({
        product: item,
        cartProduct,
        customerSource,
        canPurchaseDex,
        canPurchaseApudex,
      });

      return (
        <ProductCard
          className={css('product-card')}
          clickingDelay={PRODUCT_COUNTER_CLICKING_DELAY}
          defaultImageSrc={ImageRoutes.CATALOGUE_DEFAULT_PRODUCT}
          isLoading={false}
          key={`product-card-${item.sku}-${title}`}
          onAbort={() => onAbort(item.sku, unitMeasureCode)}
          onCardClick={() => onDetailProduct(item)}
          onChangeQuantity={newQuantity =>
            onChangeCounterQuantity({
              item,
              currentQuantity: quantity,
              newQuantity,
              unitMeasureCode,
              index,
              metadata: cartProduct?.metadata,
              suggestedProductType,
            })
          }
          product={cardProductData}
          quantity={quantity}
          tags={tags}
          variant={variant}
          active={cardProductData.active}
          infoLine={infoLine}
          infoLabel={infoLabel}
        />
      );
    });

    if (children && shouldSeeMore) {
      productCards.push(children as JSX.Element);
    }

    return productCards;
  };

  return (
    <InView
      as="div"
      aria-live="polite"
      aria-label="lista de productos"
      triggerOnce
      onChange={inView => inView && onViewCarousel?.(products)}
      className={css()}
    >
      <SkeletonPlaceHolder
        loading={!products?.length}
        skeleton={<CarouselSkeleton ariaLabel="cargando productos" variant={variant} />}
      >
        <SlickSlider items={renderProductCards()} />
      </SkeletonPlaceHolder>
    </InView>
  );
};
