import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { authGetCustomerSelector } from 'core/store/auth/selectors';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { handleServiceException } from 'shared/utils/errors.utils';
import {
  getCustomerIdFromCheckout,
  mapToInitialCheckoutParams,
  mapToInitialCheckoutValues,
} from '../checkout-state.utils';
import { checkoutInitialState, resetCheckoutLoading, SLICE_NAMESPACE } from '../constants';
import { ICheckoutState, IGetInitialCheckoutDetail } from '../types';

export const getCheckoutDetailAction = createAppAsyncThunk<IGetInitialCheckoutDetail, void>(
  `${SLICE_NAMESPACE}/getCheckoutDetail`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const customerId = getCustomerIdFromCheckout(state.checkout);
      const customer = authGetCustomerSelector(state);
      const { dex, apudex, source } = customer;

      const params = mapToInitialCheckoutParams({ dex, apudex, customerSource: source });
      const detail = await apiService.checkout.getCartDetail(customerId, params);
      const initialValues = mapToInitialCheckoutValues(detail, customer);

      return {
        detail,
        initialValues,
      };
    } catch (error) {
      return rejectWithValue(handleServiceException(error));
    }
  },
);

export const getCheckoutDetailReducer: TSliceExtraReducer<ICheckoutState> = builder => {
  builder
    .addCase(getCheckoutDetailAction.pending, state => {
      state.loading = {
        global: true,
        alicorpCart: false,
        alliedCart: false,
        generalCart: false,
      };
      state.error = checkoutInitialState.error;
    })
    .addCase(getCheckoutDetailAction.fulfilled, (state, { payload }) => {
      const { detail, initialValues } = payload;
      state.loading = resetCheckoutLoading;
      state.error = checkoutInitialState.error;
      state.detail = detail;

      if (state.shipping.idle) {
        state.shipping.deliveryDates = initialValues.deliveryDates;
        state.paymentMethod = initialValues.paymentMethod;
        state.shipping.idle = false;
      }
    })
    .addCase(getCheckoutDetailAction.rejected, (state, { payload }) => {
      const { shipping, paymentMethod, error: initialError } = checkoutInitialState;
      state.loading = resetCheckoutLoading;
      if (payload)
        state.error = {
          ...initialError,
          global: payload,
        };
      state.paymentMethod = paymentMethod;
      state.shipping = shipping;
    });

  return builder;
};
