/* eslint-disable no-console */
import { IMonitoringProvider } from 'core/model/interfaces/monitoring.interface';

export class MonitoringGeneric {
  private provider: IMonitoringProvider;
  applicationVersion: string | null = null;
  userId: string | null = null;
  isMonitoringEnabled = false;

  constructor(provider: IMonitoringProvider, appVersion: string | null) {
    this.provider = provider;
    this.isMonitoringEnabled = this.checkIsMonitoringEnabled();
    this.setApplicationVersion(appVersion);
  }

  setApplicationVersion(appVersion: string | null): void {
    if (!this.isMonitoringEnabled) {
      console.warn('The monitoring provider is not enabled, cannot set application version');
      return;
    }
    this.provider.setApplicationVersion(appVersion);
    this.applicationVersion = appVersion;
  }

  setUserId(userId: string | null): void {
    if (!this.isMonitoringEnabled) {
      console.warn('The monitoring provider is not enabled, cannot set user id');
      return;
    }
    this.provider.setUserId(userId);
    this.userId = userId;
  }

  checkIsMonitoringEnabled(): boolean {
    return this.provider.checkIsMonitoringEnabled();
  }

  createReduxEnhancer() {
    return this.provider.createReduxEnhancer();
  }
}
