import { ILayoutState } from './types';

export const SLICE_NAMESPACE = 'layout';

export const layoutInitialState: ILayoutState = {
  search: '',
  menu: false,
  cart: false,
  isNotFound: false,
  isAddressModalOpen: false,
};
