import { useNavigate } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import { catalogueFiltersSelector } from 'core/store/navigation/selectors';
import { useAppSelector } from 'core/store/store.utils';

const useCatalogNavigation = (categoryId?: string, subcategoryId?: string) => {
  const { categories } = useAppSelector(catalogueFiltersSelector);
  const activeCategory = categories.find(c => c.itemId.toString() === categoryId);
  const categoryUrl = activeCategory?.url;
  const subcategoryUrl = activeCategory?.items.find(sc => sc.itemId.toString() === subcategoryId)?.url;

  const navigate = useNavigate();

  const goToCategoriesCatalog = (url?: string) => {
    if (url) {
      navigate(`${Path.CATALOG}${url}`);
      return;
    }

    if (categoryId) {
      navigate(`${Path.CATALOG}${categoryUrl}`);
    }
  };

  const goToSubcategoriesCatalog = (url?: string) => {
    if (url) {
      navigate(`${Path.CATALOG}${url}`);
      return;
    }

    if (subcategoryId) {
      navigate(`${Path.CATALOG}${subcategoryUrl}`);
    }
  };

  return { goToCategoriesCatalog, goToSubcategoriesCatalog };
};

export default useCatalogNavigation;
