export enum ECartActions {
  REFRESH = 'refresh-items-reduced-by-stock',
}

export enum ECartError {
  SHOW_ERROR_AND_CLOSE_CART = 'SHOW_ERRORS_AND_CLOSE_CART',
  SHOW_USER_VALIDATION_ERROR = 'SHOW_USER_VALIDATION_ERROR',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  SHOW_ERROR_AND_KEEP_CART_OPEN = 'SHOW_ERRORS_AND_KEEP_CART_OPEN',
}
