import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './product-card-horizontal-skeleton.scss';

export const ProductCardHorizontalSkeleton = () => {
  const css = useCSS('product-card-horizontal-skeleton');

  return (
    <div className={css()}>
      <Skeleton className={css('image')} />
      <div className={css('column')}>
        <div className={css('name')}>
          <Skeleton />
        </div>
        <div className={css('description')}>
          <Skeleton />
        </div>
        <div className={css('price')}>
          <Skeleton className={css('skl')} />
        </div>
      </div>
    </div>
  );
};
