import { useCSS } from '@insuma/mpp-ui/hooks';
import { Dot } from 'shared/components/dot';

import './slider-dots.scss';

interface ISliderDotsProps {
  slides: Array<{
    imageUrl: string;
    redirectionUrl: string | null;
  }>;
  activeSlide: number;
}

export const SliderDots = ({ slides, activeSlide }: ISliderDotsProps) => {
  const css = useCSS('slider-dots');

  return (
    <div className={css()} role="tablist">
      {slides.map((slide, i) => (
        <Dot key={slide.imageUrl} active={activeSlide === i} />
      ))}
    </div>
  );
};
