import { useEffect, useRef, useState } from 'react';
import { DEFAULT_CATALOG_ITEMS_PER_PAGE, DEFAULT_CATALOG_PAGE } from 'core/constants/catalogue.constants';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import { catalogsService } from 'core/services';
import { checkoutCustomerSelector } from 'core/store/checkout/selectors';
import { useAppSelector } from 'core/store/store.utils';
import { preload } from 'shared/utils/resource-hints.utils';

const usePaginatedCatalogs = (list: Array<{ id: string; name: string }>, categoryId?: string) => {
  const itemsPerRequest = 3;
  const totalPages = Math.ceil(list.length / itemsPerRequest);
  const [pageCounter, setPageCounter] = useState(0);
  const [catalogs, setCatalogs] = useState<Array<ICatalogueProducts>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isIdle, setIsIdle] = useState(true);
  const lastItemRef = useRef(null);
  const currentQuery = list
    .map(l => l.id)
    .slice(pageCounter * itemsPerRequest, pageCounter * itemsPerRequest + itemsPerRequest)
    .join(',');
  const customerId = useAppSelector(checkoutCustomerSelector);

  useEffect(() => {
    setIsFirstLoading(true);
    setCatalogs([]);
    setPageCounter(0);
  }, [categoryId]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !isLoading && pageCounter < totalPages && customerId) {
        setPageCounter(prevPage => prevPage + 1);
        setIsLoading(true);
        catalogsService
          .getCatalogProducts(
            {
              navigationItemIds: currentQuery,
              page: DEFAULT_CATALOG_PAGE,
              itemsPerPage: DEFAULT_CATALOG_ITEMS_PER_PAGE,
            },
            customerId,
            categoryId,
          )
          .then(enrichedItems => {
            const firstProduct = enrichedItems[0]?.products[0]?.image;
            if (firstProduct) preload(firstProduct);
            setCatalogs(prevItems => [...prevItems, ...enrichedItems]);
          })
          .finally(() => {
            setIsLoading(false);
            setIsFirstLoading(false);
            setIsIdle(false);
          });
      }
    });
    if (lastItemRef.current) {
      observer.observe(lastItemRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [pageCounter, isLoading, currentQuery, totalPages, isIdle, categoryId, customerId]);

  return { catalogs, lastItemRef, isLoading, isIdle, isFirstLoading };
};

export default usePaginatedCatalogs;
