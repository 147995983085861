export enum ESuggestedProductPage {
  internalPage = 'productos-sugeridos',
  homepage = 'home',
}

export enum ESuggestedProductViewId {
  internalPage = 1,
  shoppingCart = 2,
  homepage = 3,
  fixedBanner = 4,
}

export enum ESuggestedProductViewName {
  internalPage = 'Página interna',
  shoppingCart = 'Carrito de compra',
  homepage = 'Home',
  fixedBanner = 'Banner',
}

export enum ESuggestionCode {
  unforgivables = 'I',
  enhancers = 'P',
  idealSelection = 'S',
}

export enum ESuggestionLabel {
  unforgivables = 'Imperdonables',
  enhancers = 'Potenciadores',
  idealSelection = 'Surtido Ideal',
}
