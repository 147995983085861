import { EMenuEntryType } from 'core/model/enums/navigation.enum';
import { INavigationState } from './types';

export const SLICE_NAMESPACE = 'navigation';
export const INITIAL_SELECTED_ENTRY = {};

export const navigationInitialState: INavigationState = {
  categories: {
    list: [],
    loading: true,
    error: null,
    idle: true,
  },
  menuLinks: {
    footer: [],
    header: [],
    sidebar: [],
  },
  selectedEntry: {
    id: '',
    name: '',
    url: '',
    items: [],
    selectedSubentry: null,
    type: EMenuEntryType.STATIC,
  },
  subcategoriesDrawerOpen: false,
};
