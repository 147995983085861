import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@alicorpdigital/dali-components-react';
import { Button } from '@insuma/mpp-ui/components/button';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { Path } from 'core/constants/path.constants';
import { useScreen } from 'core/contexts/screen.context';
import { IOption } from 'core/model/interfaces/menu.interface';
import { toggleSubcategoriesDrawer } from 'core/store/navigation';
import { navigationSelector } from 'core/store/navigation/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';
import { Drawer } from 'shared/components/drawer';
import { limitCharacters } from 'shared/utils/limit-strings.utils';

import './menu-subcategories-drawer.scss';

export const MenuSubcategoriesDrawer = () => {
  const css = useCSS('mpp-subcategories-drawer');
  const navigate = useNavigate();
  const { width } = useScreen();
  const dispatch = useAppDispatch();
  const { selectedEntry, subcategoriesDrawerOpen } = useAppSelector(navigationSelector);
  const options = selectedEntry.items;
  const isMobile = width <= 880;

  const isSelectedOption = (optionId: string) => (selectedEntry.selectedSubentry?.id || '') === optionId;

  const isSelectedAll = isSelectedOption('');

  const handleCloseDrawer = useCallback(() => {
    dispatch(toggleSubcategoriesDrawer(false));
  }, [dispatch]);

  const onSelect = (option: IOption | null) => {
    if (option) navigate(`${Path.CATALOG}${option.url}`);
    else navigate(`${Path.CATALOG}${selectedEntry.url}`);
    handleCloseDrawer();
  };

  useEffect(() => {
    if (!isMobile) handleCloseDrawer();
  }, [isMobile, handleCloseDrawer]);

  if (selectedEntry.items.length === 0) return null;

  return (
    <Drawer visible={subcategoriesDrawerOpen} direction="right" close={handleCloseDrawer}>
      <div className={css()} role="dialog">
        <Button variant="tertiary" className={css('close-button')} onClick={handleCloseDrawer}>
          <Icon name="caret-left" className={css('caret-left')} />
          Regresar
        </Button>
        <div className={css('body')}>
          <Heading className={css('title')}>Subcategoría</Heading>
        </div>
        <div className={css('list')} role="listbox">
          <button
            type="button"
            className={css('chip', { selected: isSelectedAll })}
            key="todos"
            role="option"
            aria-selected={isSelectedAll}
            onClick={() => onSelect(null)}
          >
            Todos
          </button>
          {options.map(option => {
            // TODO[usecss]: Refactor this to use a single variable when usecss is updated
            const selected = isSelectedOption(option.id);
            return (
              <button
                key={option.id}
                type="button"
                role="option"
                aria-selected={selected}
                className={css('chip', { selected })}
                onClick={() => onSelect(option)}
              >
                {limitCharacters(option.name, 24)}
              </button>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};
