import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import { ISuggestedProductsState } from './types';

export const SLICE_NAMESPACE = 'suggested-products';

export const suggestedProductsInitialState: ISuggestedProductsState = {
  sections: {
    [ESuggestedProductViewId.fixedBanner]: {
      suggestions: [],
      isLoading: true,
    },
    [ESuggestedProductViewId.homepage]: {
      suggestions: [],
      isLoading: true,
    },
    [ESuggestedProductViewId.internalPage]: {
      suggestions: [],
      isLoading: true,
    },
    [ESuggestedProductViewId.shoppingCart]: {
      suggestions: [],
      isLoading: true,
    },
  },
};
