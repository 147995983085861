import { TProductCardVariant } from '@insuma/mpp-ui/components/product-card';
import { ProductCardDefaultSkeleton } from './product-card-default-skeleton';
import { ProductCardHorizontalSkeleton } from './product-card-horizontal-skeleton';
import { ProductCardSimplifiedSkeleton } from './product-card-simplified-skeleton';

interface IProductCardsSkeletonProps {
  variant?: TProductCardVariant;
}
export const ProductCardsSkeleton = ({ variant = 'default' }: IProductCardsSkeletonProps) => {
  switch (variant) {
    case 'horizontal':
      return <ProductCardHorizontalSkeleton />;
    case 'simplified':
      return <ProductCardSimplifiedSkeleton />;
    case 'default':
    default:
      return <ProductCardDefaultSkeleton />;
  }
};
