// TODO: Credit - uncomment
// export const HYBRID_WITH_DEBT_IN_BOTH_SOURCES_MESSAGE =
//   'Tienes una deuda vencida y por eso no puedes agregar productos. Comunícate con tu vendedor para más información.';
export const HYBRID_WITH_DEBT_IN_BOTH_SOURCES_MESSAGE =
  'Tienes una deuda vencida. Comunícate con tu vendedor para más información.';

// export const HYBRID_WITH_DEBT_IN_DEX_MESSAGE =
//   'Tienes una deuda vencida y por eso no puedes agregar productos Alicorp. Comunícate con tu vendedor para más información.';
export const HYBRID_WITH_DEBT_IN_DEX_MESSAGE =
  'Tienes una deuda vencida. Comunícate con tu vendedor para más información.';

// export const HYBRID_WITH_DEBT_IN_APUDEX_MESSAGE =
//   'Tienes una deuda vencida y por eso no puedes agregar productos Aliados. Comunícate con tu vendedor para más información.';
export const HYBRID_WITH_DEBT_IN_APUDEX_MESSAGE =
  'Tienes una deuda vencida. Comunícate con tu vendedor para más información.';

// export const DEX_WITH_DEBT_MESSAGE =
//   'Tienes una deuda vencida y por eso no puedes agregar productos. Comunícate con tu vendedor para más información.';
export const DEX_WITH_DEBT_MESSAGE = 'Tienes una deuda vencida. Comunícate con tu vendedor para más información.';

const WHATSAPP_APUDEX_NUMBER = '985769373';

// export const APUDEX_WITH_DEBT_MESSAGE = `Tienes una deuda vencida y por eso no puedes agregar productos. Comunícate al ${WHATSAPP_APUDEX_NUMBER} para más información.`;
export const APUDEX_WITH_DEBT_MESSAGE = `Tienes una deuda vencida. Comunícate al ${WHATSAPP_APUDEX_NUMBER} para más información.`;

export const CREDIT_DEBT_LINK_APUDEX = `https://wa.me/51${WHATSAPP_APUDEX_NUMBER}`;
