import { IOrdersState } from './types';

export const SLICE_NAMESPACE = 'orders';

export const ordersInitialState: IOrdersState = {
  ordersList: [],
  loading: false,
  loadingDetail: false,
  error: null,
  errorDetail: null,
  orderDetail: {
    id: '',
    descriptionTotalDiscount: '',
    totalDiscount: 0,
    products: [],
    currencySymbol: '',
    subTotal: 0,
    perception: 0,
    finalTotal: 0,
  },
};
