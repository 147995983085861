import { EEnvironment } from 'core/model/enums/environment.enum';

export const getEnvValue = (key: keyof ImportMetaEnv): string => {
  if (import.meta.env[key]) return import.meta.env[key];
  return window._env_?.[key];
};

export const isProduction = (): boolean => getEnvValue('VITE_ENV') === EEnvironment.PRODUCTION;

export const isTestingEnv = (): boolean => getEnvValue('NODE_ENV') === EEnvironment.VITEST || !!getEnvValue('VITEST');

export const isLocalEnv = () => !isProduction() && !isTestingEnv();
