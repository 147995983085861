import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import useInitializeAddresses from 'core/hooks/app/use-initialize-addresses';
import MainLayout from 'shared/components/main-components/main-layout';
import { MenuLayout } from 'shared/components/main-components/menu-layout';
import { ProtectedRoutes } from 'shared/components/protected-routes';
import { SuspensePage } from 'shared/components/suspense/supense-page';
import Home from './home/home';
import { IndexPage } from './index-page';
import {
  LazyCategoryCatalog,
  LazyCheckout,
  LazyHighlightedProductsPages,
  LazyLogout,
  LazyMyOrders,
  LazyMySession,
  LazyNotFound,
  LazyProductDetail,
  LazyPromotionProductsPages,
  LazySearchResults,
  LazyStaticPages,
  LazySubcategoryCatalog,
  LazySuggestedProductsPages,
} from './screens.lazy';

export const MainRoutes = () => {
  const { isLogged } = useInitializeAddresses();

  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      {!isLogged && (
        <Route
          path={`${Path.STATIC_PAGES}/:slug`}
          element={
            <MainLayout>
              <SuspensePage>
                <LazyStaticPages />
              </SuspensePage>
            </MainLayout>
          }
        />
      )}
      <Route
        element={
          <ProtectedRoutes>
            <MainLayout>
              <SuspensePage>
                <Outlet />
              </SuspensePage>
            </MainLayout>
          </ProtectedRoutes>
        }
      >
        <Route path={Path.LOGOUT} element={<LazyLogout />} />
        <Route path={Path.MY_ORDERS} element={<LazyMyOrders />} />
        <Route path={Path.SEARCH} element={<LazySearchResults />} />
        <Route path={`${Path.PRODUCT}/:id`} element={<LazyProductDetail />} />
        <Route path={Path.MY_SESSION} element={<LazyMySession />} />
        <Route path={`${Path.CHECKOUT}/*`} element={<LazyCheckout />} />
        <Route element={<MenuLayout />}>
          <Route path={Path.HOME} element={<Home />} />
          <Route path={Path.SUGGESTED_PRODUCTS_PAGE} element={<LazySuggestedProductsPages />} />
          <Route path={Path.PROMOTIONS} element={<LazyPromotionProductsPages />} />
          <Route path={Path.HIGHLIGHTED} element={<LazyHighlightedProductsPages />} />
          <Route path={`${Path.CATALOG}/:categorySlug`} element={<LazyCategoryCatalog />} />
          <Route path={`${Path.CATALOG}/:categorySlug/:subcategorySlug`} element={<LazySubcategoryCatalog />} />
          <Route path={`${Path.STATIC_PAGES}/:slug`} element={<LazyStaticPages />} />
        </Route>
        <Route path="*" element={<LazyNotFound />} />
      </Route>
    </Routes>
  );
};
