import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { SLICE_NAMESPACE, suggestedProductsInitialState } from './constants';

export const suggestedProductsSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: suggestedProductsInitialState,
  extraReducers,
  reducers: {},
});

const { reducer: suggestedProductsReducer } = suggestedProductsSlice;

export default suggestedProductsReducer;
