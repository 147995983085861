import { createSelector } from '@reduxjs/toolkit';
import { TDistributorSource } from 'core/model/interfaces/checkout.interface';
import { authCustomerIsHybridSelector } from 'core/store/auth/selectors';
import { canBuySelector, hasCreditsSelector } from 'core/store/credits/selectors';
import { isFill } from 'shared/utils/string.utils';
import {
  checkoutDetailSelector,
  checkoutShippingSelector,
  paymentMethodSelector,
  selectedDeliveryDatesSelector,
} from './core.selectors';

export const shippingDeliveryDatesSelector = createSelector(
  checkoutShippingSelector,
  shipping => shipping.deliveryDates,
);

export const checkoutDeletedProductsSelector = createSelector(checkoutDetailSelector, detail => detail.deletedProducts);

export const hasEmptyDeliveryDatesSelector = createSelector(selectedDeliveryDatesSelector, deliveryDates => {
  const distributorDeliveryDates = Object.keys(deliveryDates) as Array<TDistributorSource>;
  const hasDeliveryDates = distributorDeliveryDates.some(
    distributor => Object.keys(deliveryDates[distributor]).length > 0,
  );
  return !hasDeliveryDates;
});

export const checkoutGroupingDatesSelector = createSelector(
  authCustomerIsHybridSelector,
  checkoutDetailSelector,
  (isHybrid, detail) => {
    if (!isHybrid) {
      return detail.generalCart.groupingRules;
    }
    return [...detail.alicorpCart.groupingRules, ...detail.alliedCart.groupingRules];
  },
);

export const canEnableShippingSubmitSelector = createSelector(
  authCustomerIsHybridSelector,
  checkoutGroupingDatesSelector,
  paymentMethodSelector,
  hasCreditsSelector,
  canBuySelector,
  (isHybrid, groupingRules, paymentMethods, hasCredits, canBuy) => {
    const hasEmptyGroupingRules = groupingRules.length === 0;

    if (hasEmptyGroupingRules) return false;
    if (isHybrid) return canBuy;
    if (!hasCredits) return isFill(paymentMethods.generalCart.id);
    return canBuy;
  },
);

export const checkoutAllProductsSelector = createSelector(
  authCustomerIsHybridSelector,
  checkoutDetailSelector,
  (isHybrid, detail) => {
    if (!isHybrid) {
      return detail.generalCart.groupingRules.flatMap(gr => gr.products);
    }
    return [
      ...detail.alicorpCart.groupingRules.flatMap(gr => gr.products),
      ...detail.alliedCart.groupingRules.flatMap(gr => gr.products),
    ];
  },
);
