import { Path } from './path.constants';

export const ROUTES = {
  CHECKOUT_SHIPPING: `${Path.CHECKOUT}${Path.CHECKOUT_SHIPPING}`,
  CHECKOUT_PURCHASE: `${Path.CHECKOUT}${Path.CHECKOUT_PURCHASE}`,
  CHECKOUT_ORDER_SUCCESS: `${Path.CHECKOUT}${Path.CHECKOUT_ORDER_SUCCESS}`,
};

export const LAYOUT_IGNORED_ROUTES = {
  HEADER: [Path.LOGOUT],
  FOOTER: [Path.LOGOUT, ROUTES.CHECKOUT_SHIPPING, ROUTES.CHECKOUT_PURCHASE, ROUTES.CHECKOUT_ORDER_SUCCESS],
};
