import * as categoryEvents from './category.events';
import * as checkoutEvents from './checkout.events';
import * as commonEvents from './common.events';
import * as creditEvents from './credit.events';
import * as loginEvents from './login.events';
import * as productEvents from './product.events';
import * as productsEvents from './products.events';
import * as promosEvents from './promos.events';
import * as shoppingCartEvents from './shopping-cart.events';

const gtmEvents = {
  ...loginEvents,
  ...shoppingCartEvents,
  ...checkoutEvents,
  ...productsEvents,
  ...promosEvents,
  ...commonEvents,
  ...categoryEvents,
  ...creditEvents,
  ...productEvents,
};

export default gtmEvents;
