import { shoppingCartService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { dispatchGetSuggestedProductsForAllLocations } from 'core/store/suggested-products/utils';
import { SLICE_NAMESPACE } from '../constants';
import { ICartState } from '../types';

export const removeProductAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/removeProductAction`,
  async (sku: string, { dispatch }) => {
    await shoppingCartService.deleteProductShoppingCart(sku);
    dispatchGetSuggestedProductsForAllLocations(dispatch);
  },
);

export const removeProductReducer: TSliceExtraReducer<ICartState> = builder => {
  builder
    .addCase(removeProductAction.pending, (state, { meta }) => {
      state.productsLoading = [...state.productsLoading, meta.arg];
    })
    .addCase(removeProductAction.fulfilled, (state, { meta }) => {
      state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg);
    })
    .addCase(removeProductAction.rejected, (state, { meta }) => {
      state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg);
    });

  return builder;
};
