import { ISuggestionSection } from 'core/model/interfaces/suggested-products.interface';
import { mapProductDTOsToIProducts } from '../catalogs/catalogs.mappers';
import { ISuggestionPageDTO } from './suggested-products.dto';

export const getSuggestionSections = (pages: Array<ISuggestionPageDTO>): Array<ISuggestionSection> => {
  const { suggestions } = pages[0];

  return suggestions.map(s => ({
    title: s?.description,
    type: s?.code,
    products: mapProductDTOsToIProducts(s?.products),
    hasMoreProducts: s.hasMoreProducts,
    quantity: s.quantity,
  }));
};
