import { memo } from 'react';
import { Icon } from '@insuma/mpp-ui/components/icon';

interface IArrowProps {
  direction: 'right' | 'left';
  handleClick?: () => void;
  disabled?: boolean;
  ariaLabel?: string;
}

export const Arrow = memo(({ direction, handleClick, ariaLabel, disabled = false }: IArrowProps) => (
  <button
    className={direction}
    type="button"
    disabled={disabled}
    onClick={handleClick}
    title={direction}
    aria-label={ariaLabel}
  >
    <Icon size="lg" name={`caret-${direction}`} />
  </button>
));
