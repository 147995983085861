import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { customizationInitialState, SLICE_NAMESPACE } from './constants';

export const customizationSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: customizationInitialState,
  reducers: {},
  extraReducers,
});

export default customizationSlice.reducer;
