import { GenericAbortSignal } from 'axios';
import { IAddedProduct } from 'core/model/interfaces/product.interface';
import { shoppingCartData } from './shopping-cart.data';
import { IGetShoppingCartParams } from './shopping-cart.dto';
import { mapCartDTOToShoppingCart, mapChangeProductParamsToPutParams } from './shopping-cart.mapper';

export const shoppingCartService = {
  getShoppingCart: async (customerId: number, params?: IGetShoppingCartParams, shouldRetry?: boolean) => {
    const response = await shoppingCartData.getShoppingCart(customerId, params, shouldRetry);
    return mapCartDTOToShoppingCart(response);
  },

  putShoppingCart: async (params: IAddedProduct, customerId: number, signal?: GenericAbortSignal) => {
    const mappedBody = mapChangeProductParamsToPutParams(params);
    return shoppingCartData.putShoppingCart(mappedBody, customerId, signal);
  },

  deleteProductShoppingCart: async (productId: string) => shoppingCartData.deleteProductShoppingCart(productId),
};
