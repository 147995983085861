import { ICheckoutDetailGroup } from 'core/model/interfaces/checkout.interface';
import { EMPTY_SHOPPING_CART_AMOUNTS } from '../cart/constants';
import { ICheckoutState } from './types';

export const SLICE_NAMESPACE = 'checkout';

const initialCheckoutDetail: ICheckoutDetailGroup = {
  groupingRules: [],
  amounts: EMPTY_SHOPPING_CART_AMOUNTS,
};

export const resetCheckoutLoading = {
  global: false,
  alicorpCart: false,
  alliedCart: false,
  generalCart: false,
};

export const checkoutInitialState: ICheckoutState = {
  shipping: {
    idle: true,
    deliveryDates: {
      alicorpCart: {},
      alliedCart: {},
      generalCart: {},
    },
  },
  address: {},
  paymentMethod: {
    alicorpCart: {},
    alliedCart: {},
    generalCart: {},
  },
  detail: {
    alicorpCart: { ...initialCheckoutDetail },
    alliedCart: { ...initialCheckoutDetail },
    generalCart: { ...initialCheckoutDetail },
    amounts: {
      final: 0,
      discount: 0,
      base: 0,
      igv: 0,
      perception: 0,
      price: 0,
    },
    deletedProducts: [],
  },
  status: {
    submitting: false,
    completed: false,
  },
  error: {
    global: null,
    alicorpCart: null,
    alliedCart: null,
    generalCart: null,
    createOrder: null,
  },
  loading: {
    global: true,
    alicorpCart: false,
    alliedCart: false,
    generalCart: false,
  },
  orderSuccessDetail: {
    info: null,
    orders: [],
  },
  agreements: {
    legalConsent: false,
    dataPrivacyConsent: false,
  },
} as any as ICheckoutState;
