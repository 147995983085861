import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { cartInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const cartSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: cartInitialState,
  reducers,
  extraReducers,
});

const { reducer: cartReducer } = cartSlice;

export const { setLoadingProduct, resetCart, setSuggestedProductsCarouselExpanded } = cartSlice.actions;
export default cartReducer;
