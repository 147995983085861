import { memo } from 'react';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { accessibleOnClick } from 'shared/utils/a11y.utils';

import './dot.scss';

interface IDotProps {
  active?: boolean;
  onClick?(): void;
}

export const Dot = memo(({ active = false, onClick = () => {} }: IDotProps) => {
  const css = useCSS('dot');

  return (
    <div role="tab">
      <div className={css({ active })} aria-selected={active} {...accessibleOnClick(onClick)} />
    </div>
  );
});
