import type { TSliceReducer } from 'core/store';
import { ordersInitialState } from './constants';
import { IOrdersState } from './types';

export const clearOrders: TSliceReducer<IOrdersState> = state => {
  state.ordersList = ordersInitialState.ordersList;
  state.loading = ordersInitialState.loading;
  state.error = ordersInitialState.error;
};
export const reducers = {
  clearOrders,
};
