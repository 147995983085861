import { IProductDetail } from 'core/model/interfaces/product.interface';
import type { TSliceReducer } from 'core/store';
import { productDetailInitialState } from './constants';
import { IProductDetailState } from './types';

export const updateProductDetail: TSliceReducer<IProductDetailState, IProductDetail> = (state, action) => {
  state.data = action.payload;
};

export const clearProductDetail: TSliceReducer<IProductDetailState> = state => {
  state.data = productDetailInitialState.data;
};

export const reducers = {
  clearProductDetail,
  updateProductDetail,
};
