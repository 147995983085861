import { createContext, ReactNode, useContext } from 'react';
import { MonitoringGeneric } from 'shared/utils/monitoring-providers';

export const MonitoringContext = createContext<MonitoringGeneric | undefined>(undefined);

export interface IMonitoringProviderProps {
  provider: MonitoringGeneric;
  children: ReactNode;
}

export const MonitoringProvider = ({ provider, children }: IMonitoringProviderProps) => (
  <MonitoringContext.Provider value={provider}>{children}</MonitoringContext.Provider>
);

export const useMonitoring = () => {
  const context = useContext(MonitoringContext);
  if (context === undefined) {
    throw new Error('useMonitoring must be used within a MonitoringProvider');
  }
  return context;
};
