import { createSelector } from '@reduxjs/toolkit';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { ICreditLimit } from 'core/model/interfaces/credits.interface';
import { authGetCustomerSource } from '../auth/selectors';
import { TRootState } from '../reducers';
import { hasDebtBySource } from './utils';

export const creditLimitsSelector = (state: TRootState) => state.credits.creditLimits;

export const creditLimitsBySourceSelector = createSelector(creditLimitsSelector, state =>
  state.reduce<Record<number, ICreditLimit>>((acc, current) => {
    acc[current.sourceId] = current;
    return acc;
  }, {}),
);
// TODO: Credit - uncomment
// export const canBuySelector = createSelector(
//   authGetCustomerSource,
//   creditLimitsBySourceSelector,
//   (source, creditLimits) =>
//     source === ECustomerSource.HYBRID
//       ? canPurchaseFromSource(ECustomerSource.DEX, creditLimits) ||
//         canPurchaseFromSource(ECustomerSource.APUDEX, creditLimits)
//       : canPurchaseFromSource(source, creditLimits),
// );
export const canBuySelector = createSelector(
  authGetCustomerSource,
  creditLimitsBySourceSelector,
  (_source, _creditLimits) => true,
);

export const hasDebtSelector = createSelector(
  authGetCustomerSource,
  creditLimitsBySourceSelector,
  (source, creditLimits) =>
    source === ECustomerSource.HYBRID
      ? hasDebtBySource(ECustomerSource.DEX, creditLimits) || hasDebtBySource(ECustomerSource.APUDEX, creditLimits)
      : hasDebtBySource(source, creditLimits),
);

export const hasDebtBySourceSelector = createSelector(creditLimitsBySourceSelector, creditLimits => ({
  hasDebtInDex: hasDebtBySource(ECustomerSource.DEX, creditLimits),
  hasDebtInApudex: hasDebtBySource(ECustomerSource.APUDEX, creditLimits),
}));

export const hasCreditsSelector = createSelector(
  creditLimitsBySourceSelector,
  creditLimit => Boolean(creditLimit[ECustomerSource.DEX]) || Boolean(creditLimit[ECustomerSource.APUDEX]),
);

// TODO: Credit - uncomment
// export const canBuyByDistributorSelector = createSelector(creditLimitsBySourceSelector, creditLimits => ({
//   canPurchaseDex: canPurchaseFromSource(ECustomerSource.DEX, creditLimits),
//   canPurchaseApudex: canPurchaseFromSource(ECustomerSource.APUDEX, creditLimits),
// }));
export const canBuyByDistributorSelector = createSelector(creditLimitsBySourceSelector, _creditLimits => ({
  canPurchaseDex: true,
  canPurchaseApudex: true,
}));

export const hasNoCreditsForNonHybridSelector = createSelector(
  authGetCustomerSource,
  hasCreditsSelector,
  (source, hasCredits) => {
    const isApplicable = source === ECustomerSource.DEX || source === ECustomerSource.APUDEX;

    return isApplicable && !hasCredits;
  },
);
