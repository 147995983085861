import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import { IGetSuggestionsDTO, IGetSuggestionsParams, ISuggestionPageDTO } from './suggested-products.dto';

export const baseUrl = '/gtm-product-management/v1/suggested-products';

export const suggestedProductsData = {
  getSuggestions: async (params: IGetSuggestionsParams, customerId: number): Promise<Array<ISuggestionPageDTO>> => {
    const { data } = await https.get<IServiceSuccess<IGetSuggestionsDTO>>(`${baseUrl}`, {
      params,
      headers: { 'customer-id': customerId },
    });
    return data.suggestedProducts;
  },
};
