import {
  INavigationTree,
  INavigationTreeCategory,
  INavigationTreeItem,
} from 'core/model/interfaces/navigation.interface';
import { IGetNavigationTreeDTO } from './navigation.dto';

export const navigationTreeDTOToNavigationTree = (tree: IGetNavigationTreeDTO): INavigationTree => {
  const { footer, product, sidebar } = tree;

  // TODO: Mejorar interface de INavigationTreeItemDTO
  const categories = product.items.filter(i => !i.pageId) as Array<INavigationTreeCategory>;
  const header = product.items.filter(i => i.pageId) as Array<INavigationTreeItem>;

  return {
    categories,
    footer: footer.items as Array<INavigationTreeItem>,
    header,
    sidebar: sidebar.items as Array<INavigationTreeItem>,
  };
};
