import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './product-card-default-skeleton.scss';

export const ProductCardDefaultSkeleton = () => {
  const css = useCSS('product-card-default-skeleton');
  return (
    <div className={css()}>
      <Skeleton style={{ height: '148px' }} />
      <Skeleton style={{ height: '14px' }} />
      <Skeleton style={{ width: '40%' }} />
      <Skeleton style={{ width: '50%' }} />
    </div>
  );
};
