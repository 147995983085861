import React, { HTMLProps, useCallback, useEffect, useRef, useState } from 'react';

import './drawer.scss';
// TODO: Create this component in MPP-UI
// TODO Refactor de componente Drawer
export interface IDrawerProps extends HTMLProps<HTMLDivElement> {
  visible: boolean;
  direction?: string;
  children: React.ReactNode;
  close: () => void;
  disableBodyOverflow?: boolean;
  fillScreen?: boolean;
  className?: string;
  shouldUnmountChildren?: boolean;
}

export const Drawer = ({
  visible,
  direction = 'left',
  close,
  disableBodyOverflow = true,
  fillScreen = false,
  children,
  className = '',
  shouldUnmountChildren = false,
  ...rest
}: IDrawerProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isDrawerClosed, setDrawerClosed] = useState(true);
  const showChildren = shouldUnmountChildren ? shouldUnmountChildren && !isDrawerClosed : true;

  const lockScroll = () => {
    const { body } = document;
    const scrollBarWidth = body ? window.innerWidth - body.offsetWidth : 0;
    body.style.overflow = 'hidden';
    if (scrollBarWidth) body.style.paddingRight = `${scrollBarWidth}px`;
  };

  useEffect(() => {
    if (!disableBodyOverflow) return;
    if (visible) lockScroll();
    else document.body.removeAttribute('style');
  }, [visible, disableBodyOverflow]);

  const escapeListener = useCallback(
    e => {
      if (e.key === 'Escape' && visible) close();
    },
    [close, visible],
  );

  const clickListener = useCallback(
    e => {
      if (ref.current?.contains(e.target) && visible) close();
    },
    // eslint-disable-next-line
    [ref.current, close, visible],
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [clickListener, escapeListener]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (visible) setDrawerClosed(false);
    else {
      timer = setTimeout(() => {
        setDrawerClosed(true);
      }, 400);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [visible]);

  return (
    <div className="kt-drawer" role="presentation" {...rest}>
      {!isDrawerClosed && <div ref={ref} className={`kt-drawer__backdrop ${!visible && 'hide'}`} />}
      <div
        className={`kt-drawer__container ${direction} ${visible && 'show'} ${
          fillScreen ? 'fill-screen' : ''
        } ${className}`}
        aria-hidden={!visible}
      >
        {showChildren && children}
      </div>
    </div>
  );
};
