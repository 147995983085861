import { PersistPartial } from 'redux-persist/es/persistReducer';
import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { EPaymentMethodType } from 'core/model/enums/checkout.enum';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import {
  ICheckoutDetail,
  ICheckoutDetailGroup,
  ICheckoutDetailParams,
  ICheckoutPaymentMethod,
  TShippingDeliveryDates,
} from 'core/model/interfaces/checkout.interface';
import { ICreditLimit } from 'core/model/interfaces/credits.interface';
import { ICustomer } from 'core/model/interfaces/customer.interface';
import { isFill } from 'shared/utils/string.utils';
import {
  ICanBuyForCustomerSource,
  ICheckoutState,
  IInitialCheckoutParams,
  IInitialRefetchCheckoutParams,
} from './types';

export const extractCurrency = (detail?: ICheckoutDetailGroup) => {
  if (detail) {
    return detail.groupingRules[0]?.products[0]?.currency || DEFAULT_CURRENCY;
  }
  return DEFAULT_CURRENCY;
};

export const hasProductsInGroupingRules = (detail: ICheckoutDetailGroup) =>
  detail.groupingRules.some(groupingRule => groupingRule.products.length > 0);

// TODO: con linea de crédito
// export const canBuyByDistributor = ({
//   method,
//   source,
//   creditLimits,
//   detail,
//   hasProducts,
// }: ICanBuyForCustomerSource) => {
//   if (!hasProducts) return true;

//   const canPurchase = creditLimits[source]?.canPurchase ?? true;

//   if (!canPurchase) return true;
//   if (!isFill(method.id)) return false;

//   const hasSelectedCredits = method.code === EPaymentMethodCode.CREDIT;
//   if (hasSelectedCredits) {
//     return !hasCreditInsufficiency(creditLimits[source], detail);
//   }

//   return true;
// };

export const canBuyByDistributor = ({ method, hasProducts }: ICanBuyForCustomerSource) => {
  if (!hasProducts) return true;
  return isFill(method.id);
};

export const hasCreditInsufficiency = (creditLimit: ICreditLimit, detail: ICheckoutDetailGroup) =>
  creditLimit?.availableAmount < detail.amounts?.final;

export const mapToInitialRefetchCheckoutParams = ({
  customerSource,
  paymentMethod,
  distributor,
}: IInitialRefetchCheckoutParams) => {
  const payload: ICheckoutDetailParams = {};

  if (customerSource === ECustomerSource.HYBRID) {
    payload.alicorpPaymentMethodId = paymentMethod.alicorpCart.id;
    payload.alliedPaymentMethodId = paymentMethod.alliedCart.id;
  } else if (customerSource === ECustomerSource.DEX) {
    payload.alicorpPaymentMethodId = paymentMethod[distributor].id;
  } else if (customerSource === ECustomerSource.APUDEX) {
    payload.alliedPaymentMethodId = paymentMethod[distributor].id;
  }

  return payload;
};

export const mapToInitialCheckoutParams = ({ dex, apudex, customerSource }: IInitialCheckoutParams) => {
  const paymentMethods = dex?.paymentMethods || apudex?.paymentMethods || [];

  const foundPaymentMethod = paymentMethods.find(({ type }) => type === EPaymentMethodType.IN_CASH);
  const id = foundPaymentMethod?.id;

  const payload: ICheckoutDetailParams = {};

  if (customerSource === ECustomerSource.HYBRID) {
    payload.alicorpPaymentMethodId = id;
    payload.alliedPaymentMethodId = id;
  } else if (customerSource === ECustomerSource.DEX) {
    payload.alicorpPaymentMethodId = id;
  } else if (customerSource === ECustomerSource.APUDEX) {
    payload.alliedPaymentMethodId = id;
  }

  return payload;
};

export const mapToInitialCheckoutValues = (detail: ICheckoutDetail, customer: ICustomer) => {
  const { dex, apudex, source } = customer;

  const groupingRules = [
    ...detail.generalCart.groupingRules,
    ...detail.alicorpCart.groupingRules,
    ...detail.alliedCart.groupingRules,
  ];

  const deliveryDates = groupingRules.reduce<Required<TShippingDeliveryDates>>(
    (acc, current) => {
      const firstDate = current.dates?.[0];
      if (firstDate) {
        acc[current.distributor][current.id] = firstDate;
      }
      return acc;
    },
    {
      alicorpCart: {},
      alliedCart: {},
      generalCart: {},
    },
  );

  const paymentMethods = dex?.paymentMethods || apudex?.paymentMethods || [];

  const paymentMethod: ICheckoutPaymentMethod = {
    alicorpCart: {},
    alliedCart: {},
    generalCart: {},
  };

  const foundPaymentMethod = paymentMethods.find(({ type }) => type === EPaymentMethodType.IN_CASH);
  const selectedPaymentMethod = {
    code: foundPaymentMethod?.code,
    id: foundPaymentMethod?.id,
  };

  if (source === ECustomerSource.HYBRID) {
    paymentMethod.alicorpCart = selectedPaymentMethod;
    paymentMethod.alliedCart = selectedPaymentMethod;
  } else {
    paymentMethod.generalCart = selectedPaymentMethod;
  }

  return {
    deliveryDates,
    paymentMethod,
  };
};

export const getCustomerIdFromCheckout = (checkout: ICheckoutState & PersistPartial): number => {
  const {
    address: { customerId },
  } = checkout;

  return customerId ?? 0;
};
