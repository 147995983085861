import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { checkoutInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const checkoutSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: checkoutInitialState,
  reducers,
  extraReducers,
});

const { reducer: checkoutReducer } = checkoutSlice;

export const {
  setAddress,
  setPaymentMethod,
  setDeliveryDate,
  resetIdleShipping,
  updateDataPrivacyConsent,
  updateLegalConsent,
  resetCheckoutError,
} = checkoutSlice.actions;
export default checkoutReducer;
