import { customersService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { IAddressesState } from '../types';

export const loadAddressesAction = createAppAsyncThunk(`${SLICE_NAMESPACE}/loadAddresses`, () =>
  customersService.getAddresses(),
);

export const loadAddressesReducer: TSliceExtraReducer<IAddressesState> = builder =>
  builder
    .addCase(loadAddressesAction.pending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(loadAddressesAction.fulfilled, (state, action) => {
      state.addresses = action.payload;
      state.loading = false;
    })
    .addCase(loadAddressesAction.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });
