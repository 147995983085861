import { pushObjectDataLayer } from './gtm.events';

interface ITriggerViewCreditAlert {
  zone: string;
  cta: string;
}

export const triggerViewCreditAlert = ({ zone, cta }: ITriggerViewCreditAlert) => {
  const data = {
    event: 'virtualEventD6',
    tipoevento: 'visualizacion - alerta credito',
    zona: zone,
    cta,
  };
  pushObjectDataLayer(data, triggerViewCreditAlert.name);
};
