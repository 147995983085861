export enum EPromotionTag {
  SIMPLE_DISCOUNT = 'SIMPLE_DISCOUNT',
  TIERED_DISCOUNT = 'TIERED_DISCOUNT',
  SIMPLE_BONUS = 'SIMPLE_BONUS',
  TIERED_BONUS = 'TIERED_BONUS',
  COMBO_BONUS = 'COMBO_BONUS',
  COMBO_DISCOUNT = 'COMBO_DISCOUNT',
}

export enum ETieredPromotionLimitType {
  BY_QUANTITY = 'qty',
  BY_AMOUNT = 'amt',
}

export enum EPromotionDetailTag {
  DISCOUNT = 'discount',
  BONUS = 'bonus',
}
