export const suggestedSectionsTypes = ['I', 'P', 'S'] as const;
export type TSuggestedSectionsTypes = (typeof suggestedSectionsTypes)[number];

export const suggestedSectionsNames: Map<TSuggestedSectionsTypes, string> = new Map([
  ['I', 'Imperdonables'],
  ['P', 'Potenciadores'],
  ['S', 'Surtido Ideal'],
]);

export const DEBOUNCE_TIME_TO_FETCH_SUGGESTION_PRODUCTS = 10000;
