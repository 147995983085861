import { isProduction, isTestingEnv } from '../env.utils';

export interface IDatalayerGTM {
  event: string;
  action?: any;
  ecommerce?: any;
}

export const pushObjectDataLayer = (data: IDatalayerGTM, eventName: string, clean?: boolean, name?: string) => {
  const { dataLayer } = window;
  // * LOG to track GTM event only if not production
  // eslint-disable-next-line no-console
  if (!isProduction() && !isTestingEnv()) console.log(`Enviando al GTM (evento: ${eventName}):`, data);
  if (clean && name) dataLayer?.push({ [name]: null });
  dataLayer?.push(data);
};
