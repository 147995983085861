import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'core/services/https';
import { IGetNavigationTreeDTO } from './navigation.dto';

export const baseUrl = '/gtm-classification-management/v1/navigation';
export const navigationData = {
  getNavigationTree: async (customerId: number): Promise<IGetNavigationTreeDTO> => {
    const { data } = await https.get<IServiceSuccess<IGetNavigationTreeDTO>>(baseUrl, {
      headers: { 'customer-id': customerId },
    });
    return data;
  },
};
