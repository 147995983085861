import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { addressesInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const addressesSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: addressesInitialState,
  reducers,
  extraReducers,
});

export const { clearAddresses } = addressesSlice.actions;
export default addressesSlice.reducer;
