import { createSelector } from '@reduxjs/toolkit';
import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import { IState, TRootState } from '../reducers';

const getSuggestionsSelector = (state: TRootState) => state.suggestedProducts.sections;
export const viewIdSelector = (_state: IState, viewId: ESuggestedProductViewId) => viewId;

export const getSuggestionsByViewIdSelectors = createSelector(
  getSuggestionsSelector,
  viewIdSelector,
  (state, viewId) => state[viewId],
);
