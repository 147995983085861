import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import type { TAppDispatch } from '..';
import { getSuggestedProducts } from './async-thunks';

export const dispatchGetSuggestedProductsForAllLocations = (dispatch: TAppDispatch) => {
  const locationIds: Array<ESuggestedProductViewId> = [
    ESuggestedProductViewId.fixedBanner,
    ESuggestedProductViewId.homepage,
    ESuggestedProductViewId.internalPage,
    ESuggestedProductViewId.shoppingCart,
  ];

  locationIds.forEach(locationId => dispatch(getSuggestedProducts(locationId)));
};
