import type { TSliceReducer } from 'core/store';
import { ILayoutState } from './types';

export const toogleCart: TSliceReducer<ILayoutState, boolean> = (state, action) => ({
  ...state,
  cart: action.payload,
});

export const toogleMenu: TSliceReducer<ILayoutState, boolean> = (state, action) => ({
  ...state,
  menu: action.payload,
});

export const setSearchQuery: TSliceReducer<ILayoutState, string> = (state, action) => ({
  ...state,
  search: action.payload,
});

export const setNotFound: TSliceReducer<ILayoutState, boolean> = (state, action) => ({
  ...state,
  isNotFound: action.payload,
});

export const toogleAddressModal: TSliceReducer<ILayoutState, boolean> = (state, action) => ({
  ...state,
  isAddressModalOpen: action.payload,
});

export const reducers = {
  toogleCart,
  toogleMenu,
  setSearchQuery,
  setNotFound,
  toogleAddressModal,
};
