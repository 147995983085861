import { ICheckoutDetail, TDistributorSource } from 'core/model/interfaces/checkout.interface';
import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { getCustomerIdFromCheckout, mapToInitialRefetchCheckoutParams } from '../checkout-state.utils';
import { checkoutInitialState, resetCheckoutLoading, SLICE_NAMESPACE } from '../constants';
import { ICheckoutState } from '../types';

export const refetchCheckoutDetailAction = createAppAsyncThunk<ICheckoutDetail, TDistributorSource>(
  `${SLICE_NAMESPACE}/refetchCheckoutDetail`,
  async (distributor, { getState }) => {
    const { auth, checkout } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);
    const params = mapToInitialRefetchCheckoutParams({
      distributor,
      customerSource: auth.customer.source,
      paymentMethod: checkout.paymentMethod,
    });
    return apiService.checkout.getCartDetail(customerId, params);
  },
);

export const refetchCheckoutDetailReducer: TSliceExtraReducer<ICheckoutState> = builder => {
  builder
    .addCase(refetchCheckoutDetailAction.pending, (state, { meta }) => {
      const distributor = meta.arg;
      state.error.global = null;
      state.loading[distributor] = true;
      state.error[distributor] = null;
    })
    .addCase(refetchCheckoutDetailAction.fulfilled, (state, { payload }) => {
      state.error = checkoutInitialState.error;
      state.loading = resetCheckoutLoading;
      state.detail = payload;
    })
    .addCase(refetchCheckoutDetailAction.rejected, (state, { error, meta }) => {
      state.loading = resetCheckoutLoading;
      state.error[meta.arg] = error;
    });

  return builder;
};
