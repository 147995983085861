import { ReactNode } from 'react';

import './sticky-banner.scss';

interface IStickyBannerProps {
  children: ReactNode;
  top?: 'inherit' | 'sm' | 'md';
}

export const StickyBanner = ({ children, top = 'md' }: IStickyBannerProps) => (
  <div className={`sticky-banner sticky-banner--top-${top}`}>{children}</div>
);
