import { GenericAbortSignal } from 'axios';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import { IHighlightedProductsPreview } from 'core/model/interfaces/highlighted-products.interface';
import { IPaginatedProducts } from 'core/model/interfaces/product.interface';
import { catalogsData } from './catalogs.data';
import {
  IGetCatalogProductsParams,
  IGetHighlightedProductsParams,
  IGetListProductsParams,
  IGetProductDetailParams,
} from './catalogs.dto';
import {
  catalogDetailResponseToProduct,
  highlightedProductsDtoToProducts,
  highlightedProductsDtoToProductsPreview,
  listProductsToPaginatedProducts,
  navigationCatalogToCatalogueProducts,
} from './catalogs.mappers';

export const catalogsService = {
  getListProducts: async (
    params: IGetListProductsParams,
    customerId: number,
    signal?: GenericAbortSignal,
  ): Promise<IPaginatedProducts> => {
    const listProducts = await catalogsData.getListProducts(params, customerId, signal);
    return listProductsToPaginatedProducts(listProducts);
  },

  getProductDetail: async (params: IGetProductDetailParams, customerId: number) => {
    const { product } = await catalogsData.getProductDetail(params, customerId);
    return catalogDetailResponseToProduct(product);
  },

  getCatalogProducts: async (
    params: IGetCatalogProductsParams,
    customerId: number,
    parentId?: string,
  ): Promise<Array<ICatalogueProducts>> => {
    const { navigation } = await catalogsData.getCatalogProducts(params, customerId);

    const treeItemType = parentId ? 'subcategory' : 'category';

    return navigation.map(item => navigationCatalogToCatalogueProducts(item, treeItemType));
  },
  getHighlightedProducts: async (
    params: IGetHighlightedProductsParams,
    customerId: number,
    signal?: GenericAbortSignal,
  ) => {
    const data = await catalogsData.getListProducts(
      {
        ...params,
        onlyFeaturedProducts: true,
      },
      customerId,
      signal,
    );
    if ('title' in data) {
      return highlightedProductsDtoToProducts(data);
    }
    throw new Error('The highlighted products don');
  },
  getHighlightedProductsPreview: async (customerId: number): Promise<IHighlightedProductsPreview> => {
    const data = await catalogsData.getListProducts(
      {
        onlyFeaturedProducts: true,
        itemsPerPage: 12,
        page: 1,
      },
      customerId,
    );

    if ('title' in data) {
      return highlightedProductsDtoToProductsPreview(data);
    }

    throw new Error(`The highlighted products don't have a title`);
  },
};
