import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS_WITH_INSTANT_SCROLL } from 'core/constants/path.constants';

const useScrollRestoration = (): void => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (PATHS_WITH_INSTANT_SCROLL.includes(pathname)) {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
};

export default useScrollRestoration;
