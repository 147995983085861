import { https } from 'core/services/https';
import { IApplyCouponResponse } from '../interface';

export const promotionsData = {
  applyCoupon: async (couponCode: string): Promise<IApplyCouponResponse> => {
    const response = await https.post<IApplyCouponResponse>(`/promotions/v1/coupons/${couponCode}/apply`, {});
    return Promise.resolve(response);
  },
  removeCoupon: async (couponCode: string): Promise<void> => {
    const response = await https.post<void>(`/promotions/v1/coupons/${couponCode}/remove`, {});
    return Promise.resolve(response);
  },
};
