import axios from 'axios';
import {
  GENERAL_ERROR_CODE,
  UNEXPECTED_ERROR_CODE,
  UNEXPECTED_ERROR_MESSAGE,
} from 'core/constants/error-http.constants';

interface IServiceError {
  data: unknown;
  error: {
    code: string;
    message: string;
    details: Array<unknown>;
  };
  success: boolean;
}

export interface IServiceException {
  code: string;
  message: string;
}

const isGeneralError = (error: unknown): error is Error =>
  typeof error === 'object' && error !== null && 'message' in error;

export const handleErrorMessage = (error: unknown, messageError?: string): string => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (axios.isAxiosError<IServiceError>(error) && error?.response) return error.response.data.error.message;
  if (isGeneralError(error) && error.message) return error.message;
  if (messageError) return messageError;
  return UNEXPECTED_ERROR_MESSAGE;
};

export const handleServiceException = (error: unknown, messageError?: string): IServiceException => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (axios.isAxiosError<IServiceError>(error) && error?.response)
    return { code: error.response.data.error.code, message: error.response.data.error.message };
  if (isGeneralError(error) && error.message) return { code: GENERAL_ERROR_CODE, message: error.message };
  if (messageError) return { code: GENERAL_ERROR_CODE, message: messageError };
  return { code: UNEXPECTED_ERROR_CODE, message: UNEXPECTED_ERROR_MESSAGE };
};
