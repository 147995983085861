import { EMenuEntryType } from 'core/model/enums/navigation.enum';
import type { TSliceReducer } from 'core/store';
import { INavigationState, ISelectedEntry } from './types';

const selectEntry: TSliceReducer<INavigationState, ISelectedEntry> = (state, action) => {
  state.selectedEntry = {
    ...state.selectedEntry,
    ...action.payload,
  };
};

const resetSelectedEntry: TSliceReducer<INavigationState> = state => {
  state.selectedEntry = {
    id: '',
    name: '',
    url: '',
    items: [],
    type: EMenuEntryType.STATIC,
    selectedSubentry: null,
  };
};

const toggleSubcategoriesDrawer: TSliceReducer<INavigationState, boolean> = (state, action) => {
  state.subcategoriesDrawerOpen = action.payload;
};

export const reducers = {
  resetSelectedEntry,
  selectEntry,
  toggleSubcategoriesDrawer,
};
