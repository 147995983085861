import { IOrderDetail } from '@insuma/mpp-ui/components/order-detail';
import { IOrderDisplay } from 'core/model/interfaces/order.interface';
import { ordersService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import type { IOrdersState } from '../types';

export const getOrderDetailAction = createAppAsyncThunk<
  IOrderDetail,
  { orderId: IOrderDisplay['id']; sourceId: number }
>(`${SLICE_NAMESPACE}/getOrderDetail`, ({ orderId, sourceId }) => ordersService.getOrderDetail(orderId, sourceId));

export const getOrderDetailReducer: TSliceExtraReducer<IOrdersState> = builder => {
  builder
    .addCase(getOrderDetailAction.pending, state => {
      state.loadingDetail = true;
    })
    .addCase(getOrderDetailAction.fulfilled, (state, { payload }) => {
      state.loadingDetail = false;
      state.errorDetail = null;
      state.orderDetail = payload;
    })
    .addCase(getOrderDetailAction.rejected, (state, { error }) => {
      state.loadingDetail = false;
      state.errorDetail = error;
    });

  return builder;
};
