import dayjs from 'dayjs';
import es from 'dayjs/locale/es';

export const toDayMonthShortYear = (date: Date) => {
  // Output example: 24/11/19
  const formated = new Intl.DateTimeFormat(['es-PE'], {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).format(date);
  return formated;
};

export const toHourMinute = (date: Date) => {
  const hours = date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  if (hours > 12) return `${hours - 12}:${minutes}pm`;
  return `${hours}:${minutes}am`;
};

export const capitalizeFirstLetter = (date: string) => date.charAt(0).toUpperCase() + date.slice(1);

export const formatDate = (date: Date, format?: string) =>
  dayjs(date)
    .locale(es)
    .format(format || 'YYYY-MM-DD');

const capitalize = (text: string) => text.replace(/\w/, match => match.toUpperCase());

export const toDescriptiveDate = (date: string) => {
  const localDate = dayjs(date, 'MM-DD-YYYY').locale(es);
  const weekday = capitalize(localDate.format('dddd'));
  const day = localDate.format('DD');
  const month = capitalize(localDate.format('MMMM'));
  const year = localDate.year();
  return `${weekday}, ${day} de ${month} de ${year}`;
};

export const formatDeliveryDateLabel = (deliveryDate: string) =>
  capitalizeFirstLetter(dayjs(deliveryDate).locale('es').format('dddd, DD [de] MMMM'));
