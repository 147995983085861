import type { TSliceReducer } from 'core/store';
import { addressesInitialState } from './constants';
import { IAddressesState } from './types';

export const clearAddresses: TSliceReducer<IAddressesState> = state => {
  state.addresses = addressesInitialState.addresses;
  state.error = addressesInitialState.error;
  state.loading = addressesInitialState.loading;
};

export const reducers = {
  clearAddresses,
};
