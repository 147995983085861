import { useCSS } from '@insuma/mpp-ui/hooks';
import { Loading } from '../../loading';

import './suspense-page-loading.scss';

export const SuspensePageLoading = () => {
  const css = useCSS('suspense-page-loading');

  return (
    <div className={css()} role="alert" aria-live="polite" aria-label="cargando pagina">
      <Loading />
    </div>
  );
};
