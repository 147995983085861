import { Navigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Path } from 'core/constants/path.constants';
import { SuspenseAppLoading } from 'shared/components/suspense/suspense-app-loading';

interface IProtectedRoutes {
  children: JSX.Element;
}

export const ProtectedRoutes = ({ children }: IProtectedRoutes) => {
  const { pathname, search } = useLocation();
  const completePath = pathname + search;
  const isLoggedIn = useIsAuthenticated();
  const { inProgress } = useMsal();

  if (inProgress !== 'none') {
    return <SuspenseAppLoading />;
  }
  if (isLoggedIn) {
    return children;
  }

  return <Navigate to={Path.LOGIN} state={{ from: completePath.includes(Path.LOGOUT) ? '' : completePath }} replace />;
};
