export enum EPaymentMethodCode {
  CASH = 'cash',
  TRANSFER = 'transfer',
  CREDIT = 'credit',
}

export enum EPaymentMethodType {
  IN_CASH = 'Contado',
  ON_CREDIT = 'Credito',
}

export enum EDistributorSource {
  GENERAL = 'generalCart',
  ALICORP = 'alicorpCart',
  ALLIED = 'alliedCart',
}
export enum ECheckoutError {
  NETWORK_OR_TIMEOUT_ERROR = 'NETWORK_OR_TIMEOUT_ERROR',
}
