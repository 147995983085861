const preloaded = new Set();

interface IPreloadOptions {
  as: string;
}

export const preload = (source: string, options: IPreloadOptions = { as: 'image' }) => {
  if (!source) return;
  if (!preloaded.has(source)) {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = options.as;
    link.href = source;
    document.head.appendChild(link);
    preloaded.add(source);
  }
};

export const addVersionToResource = (url: string) => `${url}?v=${__APP_VERSION__}`;
