import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { productDetailInitialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

export const productDetailSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: productDetailInitialState,
  reducers,
  extraReducers,
});

const { reducer: productDetailReducer } = productDetailSlice;

export const { clearProductDetail, updateProductDetail } = productDetailSlice.actions;
export default productDetailReducer;
