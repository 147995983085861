import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import type { TSliceReducer } from 'core/store';
import { CART_ERRORS_INITIAL_STATE, EMPTY_SHOPPING_CART_AMOUNTS, EMPTY_SHOPPING_CART_GROUPS } from './constants';
import { ICartState } from './types';

export const setLoadingProduct: TSliceReducer<ICartState, string> = (state, { payload }) => {
  state.productsLoading = [...state.productsLoading, payload];
};

export const resetCart: TSliceReducer<ICartState> = state => {
  state.amounts = EMPTY_SHOPPING_CART_AMOUNTS;
  state.itemsGroups = EMPTY_SHOPPING_CART_GROUPS;
  state.currency = DEFAULT_CURRENCY;
  state.errors = CART_ERRORS_INITIAL_STATE;
};

export const setSuggestedProductsCarouselExpanded: TSliceReducer<ICartState, boolean> = (state, { payload }) => {
  state.areSuggestedProductsExpanded = payload;
};

export const reducers = {
  setLoadingProduct,
  resetCart,
  setSuggestedProductsCarouselExpanded,
};
