import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from './async-thunks';
import { highlightedProductsInitialState, SLICE_NAMESPACE } from './constants';

export const highlightedProductsSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: highlightedProductsInitialState,
  extraReducers,
  reducers: {},
});

const { reducer: highlightedProductsReducer } = highlightedProductsSlice;

export default highlightedProductsReducer;
