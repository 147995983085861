import useCatalogNavigation from 'core/hooks/products/use-catalog-navigation';
import { EProductOrigin, EZoneEntryMenu } from 'core/model/enums/gtm.enum';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import { IProduct } from 'core/model/interfaces/product.interface';
import gtmEvents from 'shared/utils/gtm-events';
import { CarouselProducts } from '../carousel-products';

interface ICarouselCardProductsProps {
  catalog: ICatalogueProducts;
  listName?: string;
  origin: EProductOrigin;
}

export const CarouselCardProducts = ({ catalog, listName, origin }: ICarouselCardProductsProps) => {
  const { products, pagination, subcategory, category } = catalog;

  const title = subcategory ? subcategory?.name : category?.name;
  const { goToCategoriesCatalog, goToSubcategoriesCatalog } = useCatalogNavigation(category?.guid, subcategory?.guid);

  const totalItems = pagination?.totalItems || products?.length;
  const seeMore = pagination?.currentPage < (pagination?.totalPages ?? 0);

  const handleSeeAll = () => {
    gtmEvents.triggerEntryMenuEvent({
      zone: EZoneEntryMenu.SEE_MORE,
      category: category?.name || '',
      subcategory: subcategory?.name || 'Todos',
    });

    if (!subcategory) return goToCategoriesCatalog();
    return goToSubcategoriesCatalog();
  };

  const gtmRegisterListProducts = (visibleProducts: Array<IProduct>) => {
    gtmEvents.triggerViewProductListEvent({
      products: visibleProducts,
      listName,
      origin,
    });
  };

  return (
    <CarouselProducts title={title} shouldSeeMore={seeMore} products={products}>
      <CarouselProducts.Header totalItems={totalItems}>
        <CarouselProducts.SeeMoreButton onClick={handleSeeAll} />
      </CarouselProducts.Header>
      <CarouselProducts.Body origin={origin} listName={title} onViewCarousel={gtmRegisterListProducts}>
        <CarouselProducts.SeeMoreCard onSeeAllClick={handleSeeAll} />
      </CarouselProducts.Body>
    </CarouselProducts>
  );
};
