import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { ISlide } from 'shared/components/slider';
import { pushObjectDataLayer } from './gtm.events';

interface ITriggerVisualizePromotion {
  banner: ISlide;
}
export const triggerVisualizePromotionEvent = ({ banner }: ITriggerVisualizePromotion) => {
  const data = {
    event: 'view_promotion',
    ecommerce: {
      promotion_id: banner.guid,
      promotion_name: banner.name,
      creative_name: 'Home slider',
      location_id: 'Home slider',
      creative_slot: banner.position,
      url_banner: banner.imageUrl,
      currency: DEFAULT_CURRENCY.id,
    },
  };
  pushObjectDataLayer(data, triggerVisualizePromotionEvent.name);
};

export const triggerSelectPromotionEvent = ({ banner }: ITriggerVisualizePromotion) => {
  const data = {
    event: 'select_promotion',
    ecommerce: {
      promotion_id: banner.guid,
      promotion_name: banner.name,
      creative_name: 'Home slider',
      location_id: 'Home slider',
      creative_slot: banner.position,
      url_banner: banner.imageUrl,
      currency: DEFAULT_CURRENCY.id,
    },
  };
  pushObjectDataLayer(data, triggerSelectPromotionEvent.name);
};
