import { useEffect } from 'react';
import { loadProfileAction } from 'core/store/auth';
import { authLoadingSelector } from 'core/store/auth/selectors';
import { checkoutCustomerSelector } from 'core/store/checkout/selectors';
import { loadCreditLimitsAction } from 'core/store/credits';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';

const useInitializeUser = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(authLoadingSelector);
  const customerId = useAppSelector(checkoutCustomerSelector);

  useEffect(() => {
    if (customerId) {
      dispatch(loadProfileAction(customerId));
      dispatch(loadCreditLimitsAction());
    }
  }, [customerId, dispatch]);

  return {
    loading,
  };
};

export default useInitializeUser;
