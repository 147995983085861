import { createSelector } from '@reduxjs/toolkit';
import { authGetCustomerSource } from 'core/store/auth/selectors';
import { TRootState } from 'core/store/reducers';
import {
  countTotalProductsInCart,
  getCartProductsByUserSource,
  hasCustomerReachedMinSaleAmounts,
} from './cart-state.utils';

export const cartSelector = (state: TRootState) => state.cart;
export const cartItemGroupsSelector = (state: TRootState) => state.cart.itemsGroups;

export const allProductsByUserSourceSelector = createSelector(
  [authGetCustomerSource, cartItemGroupsSelector],
  getCartProductsByUserSource,
);

export const productCartSelector = (sku: string) =>
  createSelector(allProductsByUserSourceSelector, cartProducts => cartProducts.find(p => p.sku === sku));

export const totalProductsSelector = createSelector(allProductsByUserSourceSelector, countTotalProductsInCart);

export const cartHasReachedMinSaleAmountsSelector = createSelector(
  [authGetCustomerSource, cartItemGroupsSelector],
  hasCustomerReachedMinSaleAmounts,
);
export const cartProductLoadingSelector = (state: TRootState): Array<string> => state.cart.productsLoading;
export const isCartLoadingSelector = (state: TRootState) => state.cart.productsLoading.length > 0;

export const isProductLoadingSelector = (productId: string) =>
  createSelector(cartProductLoadingSelector, productsLoading => productsLoading.some(pc => pc === productId));
