import { ECustomerSource } from 'core/model/enums/customer.enum';
import { EProductType } from 'core/model/enums/product.enum';

export interface ICanPurchaseProductProps {
  productSource?: EProductType;
  customerSource: ECustomerSource;
  canPurchaseDex: boolean;
  canPurchaseApudex: boolean;
}

export const canPurchaseProduct = ({
  productSource,
  customerSource,
  canPurchaseDex = true,
  canPurchaseApudex = true,
}: ICanPurchaseProductProps): boolean => {
  switch (customerSource) {
    case ECustomerSource.APUDEX:
      return canPurchaseApudex;
    case ECustomerSource.DEX:
      if (productSource === EProductType.ALICORP) {
        return canPurchaseDex;
      }
      return false;
    case ECustomerSource.HYBRID:
      if (productSource === EProductType.ALICORP) {
        return canPurchaseDex;
      }
      if (productSource === EProductType.ALLIED) {
        return canPurchaseApudex;
      }
      return false;
    default:
      return true;
  }
};
