import { EProductOrigin } from 'core/model/enums/gtm.enum';
import { ICatalogueProducts } from 'core/model/interfaces/catalogues.interface';
import { CarouselCardProducts } from './carousel-card-products';

export interface ICatalogCarouselCardProductsProps {
  catalogs: Array<ICatalogueProducts>;
  origin: EProductOrigin;
  listName?: (catalog: ICatalogueProducts) => string;
  renderCatalog?: (catalog: ICatalogueProducts) => ICatalogueProducts;
}

export const CatalogCarouselCardProducts = ({
  catalogs = [],
  renderCatalog,
  listName,
  origin,
}: ICatalogCarouselCardProductsProps) => (
  <>
    {catalogs.map((catalog, index) => (
      <CarouselCardProducts
        key={generateId(catalog, index)}
        catalog={renderCatalog ? renderCatalog(catalog) : catalog}
        listName={listName ? listName(catalog) : catalog.category?.name ?? ''}
        origin={origin}
      />
    ))}
  </>
);

const generateId = ({ category, subcategory }: ICatalogueProducts, index: number) =>
  `${category?.guid}-${subcategory?.guid}-${index}`;
