import { pushObjectDataLayer } from './gtm.events';

interface ITriggerViewPageCat {
  title: string;
  category: string;
  subcategory: string;
}

export const triggerViewPageCat = ({ title, category, subcategory }: ITriggerViewPageCat) => {
  const data = {
    event: 'virtualEventD4',
    tipoevento: 'visualizacion',
    url_pagina: window.location.href,
    page_title: title,
    categoria_web: category,
    subcategoria_web: subcategory,
  };
  pushObjectDataLayer(data, triggerViewPageCat.name);
};
