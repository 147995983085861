import { createSelector } from '@reduxjs/toolkit';
import { EMenuEntryType } from 'core/model/enums/navigation.enum';
import { TRootState } from '../reducers';

export const navigationSelector = (state: TRootState) => state.navigation;

export const selectedEntrySelector = createSelector(navigationSelector, state => state.selectedEntry);

export const catalogueFiltersSelector = createSelector(navigationSelector, ({ categories: { list: categories } }) => ({
  categories,
}));

export const navigationMenuLinksSelector = (state: TRootState) => state.navigation.menuLinks;
export const isIdleCategoriesSelector = (state: TRootState) => state.navigation.categories.idle;
export const isLoadingCategoriesSelector = (state: TRootState) => state.navigation.categories.loading;
export const getEntryType = (_state: TRootState, entryType: EMenuEntryType) => entryType;

export const getSelectedCategorySelector = createSelector(
  selectedEntrySelector,
  getEntryType,
  (selectedEntry, entryType) => {
    if (selectedEntry.type === entryType) {
      return {
        categoryId: selectedEntry.id,
        selectedCategoryName: selectedEntry.name,
        selectedSubcategory: selectedEntry.selectedSubentry,
      };
    }
    return {
      categoryId: '',
      selectedCategoryName: '',
      selectedSubcategory: null,
    };
  },
);
