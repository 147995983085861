import { ECartActions, ECartError } from 'core/model/enums/shopping-cart.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { refreshCheckoutDetailAction } from 'core/store/checkout';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { dispatchGetSuggestedProductsForAllLocations } from 'core/store/suggested-products/utils';
import { CART_ERRORS_INITIAL_STATE, EMPTY_SHOPPING_CART_GROUPS, SLICE_NAMESPACE } from '../constants';
import { ICartState } from '../types';

export const removeProductFromCartAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/removeProductFromCartAction`,
  async (payload: IProduct & { shouldRefreshCheckoutDetails: boolean }, { getState, dispatch }) => {
    const { checkout } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);
    await apiService.shoppingCart.deleteProductShoppingCart(payload.sku);
    if (payload.shouldRefreshCheckoutDetails) dispatch(refreshCheckoutDetailAction());
    dispatchGetSuggestedProductsForAllLocations(dispatch);
    try {
      const result = await apiService.shoppingCart.getShoppingCart(customerId, { cartAction: ECartActions.REFRESH });
      return result;
    } catch (error) {
      throw new Error(ECartError.SHOW_ERROR_AND_KEEP_CART_OPEN);
    }
  },
);

export const removeProductFromCartReducer: TSliceExtraReducer<ICartState> = builder => {
  builder
    .addCase(removeProductFromCartAction.pending, (state, { meta }) => {
      state.summaryLoading = true;
      state.productsLoading = [...state.productsLoading, meta.arg.sku];
      state.success = false;
      state.errors = CART_ERRORS_INITIAL_STATE;
    })
    .addCase(removeProductFromCartAction.fulfilled, (state, { payload, meta }) => {
      state.itemsGroups = payload.itemsGroups || EMPTY_SHOPPING_CART_GROUPS;
      state.deletedProducts = payload.deletedProducts;
      state.amounts = payload.amounts;
      state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg.sku);
      state.summaryLoading = false;
      state.errors = { userValidation: payload.errorType, getData: null };
      state.success = true;
    })
    .addCase(removeProductFromCartAction.rejected, (state, { error, meta }) => {
      state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg.sku);
      state.summaryLoading = false;
      if (error?.message) state.errors.getData = error.message;
      state.success = false;
    });

  return builder;
};
