import { INavigationTree } from 'core/model/interfaces/navigation.interface';
import { navigationData } from './navigation.data';
import { navigationTreeDTOToNavigationTree } from './navigation.mappers';

export const navigationService = {
  getNavigationTree: async (customerId: number): Promise<INavigationTree> => {
    const tree = await navigationData.getNavigationTree(customerId);
    return navigationTreeDTOToNavigationTree(tree);
  },
};
