import { compose } from 'shared/utils/function.utils';
import { getCheckoutDetailReducer } from './get-checkout-detail';
import { purchaseOrderReducer } from './purchase-order';
import { refetchCheckoutDetailReducer } from './refetch-checkout-details';
import { refreshCheckoutReducer } from './refresh-checkout-detail';

export const extraReducers = compose(
  getCheckoutDetailReducer,
  purchaseOrderReducer,
  refreshCheckoutReducer,
  refetchCheckoutDetailReducer,
);

export { getCheckoutDetailAction } from './get-checkout-detail';
export { purchaseOrderAction } from './purchase-order';
export { refetchCheckoutDetailAction } from './refetch-checkout-details';
export { refreshCheckoutDetailAction } from './refresh-checkout-detail';
