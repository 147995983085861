import type { TSliceReducer } from 'core/store';
import { authInitialState } from './constants';
import { IAuthState } from './types';

export const clearAuth: TSliceReducer<IAuthState> = state => {
  state.customer = authInitialState.customer;
  state.error = authInitialState.error;
  state.loading = authInitialState.loading;
};

const updateCustomerLegals: TSliceReducer<IAuthState, { legalConsent: boolean; dataPrivacyConsent: boolean }> = (
  state,
  action,
) => {
  state.customer.legalConsent = action.payload.legalConsent;
  state.customer.dataPrivacyConsent = action.payload.dataPrivacyConsent;
};

const updateJustLoggedIn: TSliceReducer<IAuthState, boolean> = (state, action) => {
  state.justLoggedIn = action.payload;
};

export const reducers = {
  clearAuth,
  updateCustomerLegals,
  updateJustLoggedIn,
};
