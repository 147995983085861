import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { authGetCustomerSelector } from 'core/store/auth/selectors';
import { createAppAsyncThunk } from 'core/store/store.utils';
import {
  getCustomerIdFromCheckout,
  mapToInitialCheckoutParams,
  mapToInitialCheckoutValues,
} from '../checkout-state.utils';
import { checkoutInitialState, resetCheckoutLoading, SLICE_NAMESPACE } from '../constants';
import type { ICheckoutState } from '../types';

export const refreshCheckoutDetailAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/refreshCheckout`,
  async (_, { getState }) => {
    const state = getState();
    const customer = authGetCustomerSelector(state);
    const customerId = getCustomerIdFromCheckout(state.checkout);
    const { dex, apudex, source } = customer;

    const params = mapToInitialCheckoutParams({ dex, apudex, customerSource: source });
    const detail = await apiService.checkout.getCartDetail(customerId, params);
    const initialValues = mapToInitialCheckoutValues(detail, customer);

    return {
      detail,
      initialValues,
    };
  },
);

export const refreshCheckoutReducer: TSliceExtraReducer<ICheckoutState> = builder => {
  builder
    .addCase(refreshCheckoutDetailAction.fulfilled, (state, { payload }) => {
      const { detail, initialValues } = payload;
      state.loading = resetCheckoutLoading;
      state.detail = detail;
      state.shipping.deliveryDates = initialValues.deliveryDates;
      state.paymentMethod = checkoutInitialState.paymentMethod;
    })
    .addCase(refreshCheckoutDetailAction.rejected, state => {
      state.loading = resetCheckoutLoading;
    });

  return builder;
};
