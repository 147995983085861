import { useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';
import useWindowSize from 'shared/hooks/use-window-size';
import { Dot } from '../dot';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick-slider.scss';

interface ISlickSliderProps {
  items: Array<JSX.Element>;
  showDots?: boolean;
}

interface ISliderRefProps {
  slickGoTo(index: number): void;
}

export const SlickSlider = ({ items = [], showDots = false }: ISlickSliderProps) => {
  const { isDesktop } = useWindowSize();
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<ISliderRefProps>(null);

  const customPaging = useCallback(
    index => (
      <Dot
        active={currentIndex === index}
        onClick={() => {
          sliderRef.current?.slickGoTo(index);
        }}
      />
    ),
    [currentIndex],
  );

  const settings = {
    rows: 1,
    className: isDesktop ? 'slider-desktop' : 'slider-mobile',
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: isDesktop,
    draggable: false,
    swipeToSlide: !isDesktop,
    dots: showDots ? !isDesktop : false,
    afterChange: setCurrentIndex,
    customPaging,
  };

  return (
    <Slider ref={sliderRef} {...settings}>
      {items}
    </Slider>
  );
};
