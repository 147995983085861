import { Icon } from '@insuma/mpp-ui/components/icon';

import './see-more-button.scss';

interface ISeeMoreButtonProps {
  onClick: () => void;
  text?: string;
}

export const SeeMoreButton = ({ onClick, text = 'Ver más' }: ISeeMoreButtonProps) => (
  <button onClick={onClick} type="button" className="see-more-button">
    <span className="see-more-button__text">
      {text}
      <span className="see-more-button__icon">
        <Icon name="caret-right" size="sm" />
      </span>
    </span>
  </button>
);
