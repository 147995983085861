import ReactLoading, { LoadingProps } from 'react-loading';
import { DlColorBrandPrimaryMedium } from '@alicorpdigital/dali-design-tokens/build/web/theme-insuma/tokens';

import './loading.scss';

// TODO: Create this component in MPP-UI
export const Loading = ({ color = DlColorBrandPrimaryMedium, type = 'spin', ...props }: LoadingProps) => (
  <div className="mpp-loading">
    <ReactLoading type={type} color={color} {...props} />
  </div>
);
