import { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { IStaticPage } from 'core/model/interfaces/static-page.interface';
import { https } from 'core/services/https';
import { TGetStaticPageResponse } from './static-page.dto';

export const staticPageData = {
  getStaticPage: async (slug: string): Promise<IStaticPage> => {
    const { data } = await https.get<IServiceSuccess<TGetStaticPageResponse>>(
      `/gtm-static-management/v1/pages/${slug}`,
    );
    return data;
  },
};
