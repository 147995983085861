import { ReactNode } from 'react';

import './header-wrapper.scss';

interface IHeaderWrapperProps {
  children: ReactNode;
}

export const HeaderWrapper = ({ children }: IHeaderWrapperProps) => (
  <header className="header-wrapper">
    <div className="header-wrapper__container">{children}</div>
  </header>
);
