import { ECartActions, ECartError } from 'core/model/enums/shopping-cart.enum';
import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { refreshCheckoutDetailAction } from 'core/store/checkout';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { dispatchGetSuggestedProductsForAllLocations } from 'core/store/suggested-products/utils';
import { handleErrorOnChangingProductQuantity } from '../cart-state.utils';
import { CART_ERRORS_INITIAL_STATE, EMPTY_SHOPPING_CART_GROUPS, SLICE_NAMESPACE } from '../constants';
import { ICartState, IChangeProductActionParams } from '../types';

export const changeProductActionFromCart = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/changeProductFromCartAction`,
  async (payload: IChangeProductActionParams & { shouldRefreshCheckoutDetails: boolean }, { getState, dispatch }) => {
    const { layout, checkout } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);
    const isCartOpen = layout.cart;
    const cartAction = isCartOpen ? { cartAction: ECartActions.REFRESH } : undefined;
    try {
      await apiService.shoppingCart.putShoppingCart(payload, customerId);
      dispatchGetSuggestedProductsForAllLocations(dispatch);
    } catch (error) {
      handleErrorOnChangingProductQuantity(error, dispatch, cartAction);
    }

    if (payload.shouldRefreshCheckoutDetails) dispatch(refreshCheckoutDetailAction());
    try {
      const cart = await apiService.shoppingCart.getShoppingCart(customerId, cartAction);
      return { cart };
    } catch (error) {
      throw new Error(ECartError.SHOW_ERROR_AND_KEEP_CART_OPEN);
    }
  },
);

export const changeProductReducerFromCart: TSliceExtraReducer<ICartState> = builder => {
  builder
    .addCase(changeProductActionFromCart.pending, (state, { meta }) => {
      state.productsLoading = [...state.productsLoading, meta.arg.sku];
      state.summaryLoading = true;
      state.success = false;
      state.errors = CART_ERRORS_INITIAL_STATE;
    })
    .addCase(changeProductActionFromCart.fulfilled, (state, { payload, meta }) => {
      state.itemsGroups = payload.cart.itemsGroups || EMPTY_SHOPPING_CART_GROUPS;
      state.deletedProducts = payload.cart.deletedProducts;
      state.amounts = payload.cart.amounts;
      state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg.sku);
      state.errors = { userValidation: payload.cart.errorType, getData: null };
      state.summaryLoading = false;
      state.success = true;
    })
    .addCase(changeProductActionFromCart.rejected, (state, { error, meta }) => {
      state.summaryLoading = false;
      state.productsLoading = state.productsLoading.filter(pc => pc !== meta.arg.sku);
      if (error?.message && error?.message !== ECartError.UNEXPECTED_ERROR) state.errors.getData = error.message;
      state.success = false;
    });

  return builder;
};
