import { ActionReducerMapBuilder, configureStore, createAction, PayloadAction } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appReducer, initialState, rootReducer } from './reducers';

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  whitelist: ['addresses'],
};

// TODO: Mejorar tipado del rootReducer
type TRootReducer = typeof appReducer;
export const persistedReducer = persistReducer(persistConfig, rootReducer as TRootReducer);

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const resetStoreAction = createAction('store/reset');
export const persistor = persistStore(store);
export type TAppDispatch = typeof store.dispatch;
export type TSliceReducer<T, S = void> = (state: T, action: PayloadAction<S>) => T | void;
export type TSliceExtraReducer<T> = (builder: ActionReducerMapBuilder<T>) => ActionReducerMapBuilder<T>;

export default store;
