import { useCallback, useEffect, useRef, useState } from 'react';
import { GenericAbortSignal } from 'axios';
import { FIRST_LOAD_CATALOG_LIST_SKELETON } from 'core/constants/skeletons.constants';
import { ICatalogSortOption } from 'core/model/interfaces/catalogues.interface';
import { IPaginatedProducts } from 'core/model/interfaces/product.interface';
import { apiService } from 'core/services';
import { checkoutCustomerSelector } from 'core/store/checkout/selectors';
import { useAppSelector } from 'core/store/store.utils';

interface IFetchProductsParams {
  page: number;
  restart?: boolean;
  customerId: number;
  signal?: GenericAbortSignal;
}

interface IUsePaginatedProductsParams {
  orderBy: ICatalogSortOption;
  navigationItemId?: number;
  query?: string;
  hasPromotion?: boolean;
}

const initialPaginatedProducts: IPaginatedProducts = {
  pagination: {
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
    itemsPerPage: FIRST_LOAD_CATALOG_LIST_SKELETON,
  },
  products: [],
};

export const usePaginatedProducts = ({
  navigationItemId,
  orderBy,
  query,
  hasPromotion,
}: IUsePaginatedProductsParams) => {
  const customerId = useAppSelector(checkoutCustomerSelector);
  const [paginatedProducts, setPaginatedProducts] = useState<IPaginatedProducts>(initialPaginatedProducts);
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    setPaginatedProducts(prev => ({ ...prev, products: [] }));
  }, [orderBy]);

  useEffect(() => {
    setPaginatedProducts(initialPaginatedProducts);
  }, [navigationItemId, query]);

  const fetchProducts = useCallback(
    async ({ page, restart = false, customerId, signal }: IFetchProductsParams) => {
      // TODO: To check !hasPromotion
      if (!navigationItemId && !query && !hasPromotion) return;
      if (customerId) {
        try {
          setLoading(true);
          const { pagination, products } = await apiService.catalog.getListProducts(
            {
              navigationItemId: Number.isNaN(navigationItemId) ? undefined : navigationItemId,
              itemsPerPage: FIRST_LOAD_CATALOG_LIST_SKELETON,
              page,
              sortBy: orderBy.value,
              q: query,
              hasPromotion,
            },
            customerId,
            signal,
          );
          pagination.currentPage = page;
          setPaginatedProducts(oldProducts => ({
            pagination,
            products: restart ? products : [...oldProducts.products, ...products],
          }));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    },
    [hasPromotion, navigationItemId, orderBy.value, query],
  );

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (customerId) fetchProducts({ restart: true, page: 1, customerId, signal });
    return () => {
      abortController.abort();
    };
  }, [fetchProducts, customerId]);

  const lastBookElementRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      const { pagination, products } = paginatedProducts;
      observer.current = new IntersectionObserver(entries => {
        if (
          entries[0].isIntersecting &&
          products.length < pagination.totalItems &&
          products.length > 0 &&
          !loading &&
          pagination.currentPage < pagination.totalPages &&
          customerId
        )
          fetchProducts({ page: pagination.currentPage + 1, customerId });
      });
      if (node) observer.current.observe(node);
    },
    [paginatedProducts, loading, fetchProducts, customerId],
  );

  return { paginatedProducts, loading, lastBookElementRef };
};
