import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './product-card-simplified-skeleton.scss';

export const ProductCardSimplifiedSkeleton = () => {
  const css = useCSS('product-card-simplified-skeleton');

  return (
    <div className={css()}>
      <Skeleton />
      <Skeleton />
      <Skeleton style={{ width: '60%' }} />
      <Skeleton style={{ width: '40%' }} />
    </div>
  );
};
