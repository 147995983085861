import { createSelector } from '@reduxjs/toolkit';
import { EDistributorSource } from 'core/model/enums/checkout.enum';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { ICheckoutDetailGroup, TDistributorSource } from 'core/model/interfaces/checkout.interface';
import { ICreditLimit } from 'core/model/interfaces/credits.interface';
import { IPaymentType } from 'core/model/interfaces/payment-type.interface';
import {
  authCustomerIsHybridSelector,
  authGetCustomerSource,
  selectCustomerLegalConsent,
} from 'core/store/auth/selectors';
import { canBuySelector, creditLimitsBySourceSelector } from 'core/store/credits/selectors';
import {
  canBuyByDistributor,
  extractCurrency,
  hasCreditInsufficiency,
  hasProductsInGroupingRules,
} from '../checkout-state.utils';
import {
  checkoutDetailSelector,
  paymentMethodSelector,
  selectCheckoutAgreements,
  sourceInViewSelector,
} from './core.selectors';
import { paymentMethodsBySourceSelector } from './payment.selectors';

export const hasProductsOfSelector = createSelector(checkoutDetailSelector, detail => ({
  alicorpCart: hasProductsInGroupingRules(detail.alicorpCart),
  alliedCart: hasProductsInGroupingRules(detail.alliedCart),
  generalCart: hasProductsInGroupingRules(detail.generalCart),
}));

export const checkoutHasProductsBySourceSelector = createSelector(
  authCustomerIsHybridSelector,
  hasProductsOfSelector,
  sourceInViewSelector,
  (isHybrid, hasProductsOf, sourceInView) => {
    if (isHybrid) {
      return sourceInView === ECustomerSource.DEX ? hasProductsOf.alicorpCart : hasProductsOf.alliedCart;
    }
    return hasProductsOf.generalCart;
  },
);

export const checkoutFullDetailSelector = createSelector(
  authGetCustomerSource,
  checkoutDetailSelector,
  paymentMethodsBySourceSelector,
  creditLimitsBySourceSelector,
  sourceInViewSelector,
  (source, detail, paymentMethodsBySource, creditLimits, sourceInView = ECustomerSource.DEX) => {
    const { apudex, dex } = paymentMethodsBySource;
    let paymentMethods: Array<IPaymentType> | undefined = [];
    let selectedDetail: ICheckoutDetailGroup = detail.generalCart;
    let creditLimit: ICreditLimit = creditLimits[ECustomerSource.DEX];
    let sourceName: TDistributorSource = EDistributorSource.GENERAL;

    // TODO: Considerar valores de selectedDetail y creditLimit cuando no entra en ningun if
    if (source === ECustomerSource.DEX) {
      paymentMethods = dex;
      selectedDetail = detail.generalCart;
      creditLimit = creditLimits[ECustomerSource.DEX];
    } else if (source === ECustomerSource.APUDEX) {
      paymentMethods = apudex;
      selectedDetail = detail.generalCart;
      creditLimit = creditLimits[ECustomerSource.APUDEX];
    } else if (source === ECustomerSource.HYBRID) {
      const isViewDex = sourceInView === ECustomerSource.DEX;
      sourceName = isViewDex ? EDistributorSource.ALICORP : EDistributorSource.ALLIED;
      selectedDetail = isViewDex ? detail.alicorpCart : detail.alliedCart;
      paymentMethods = isViewDex ? dex : apudex;
      if (sourceInView) creditLimit = creditLimits[sourceInView];
    }

    return {
      sourceName,
      creditLimit,
      detail: selectedDetail,
      paymentMethods,
      currency: extractCurrency(selectedDetail),
      hasCreditInsufficiency: hasCreditInsufficiency(creditLimit, selectedDetail),
    };
  },
);

export const hasAcceptedlegalConsentSelector = createSelector(
  selectCustomerLegalConsent,
  selectCheckoutAgreements,
  (legalConsent, agreements) => {
    if (legalConsent) {
      return true;
    }

    return agreements.legalConsent;
  },
);

export const canEnableConfirmSubmitSelector = createSelector(
  authCustomerIsHybridSelector,
  authGetCustomerSource,
  paymentMethodSelector,
  checkoutDetailSelector,
  hasProductsOfSelector,
  creditLimitsBySourceSelector,
  canBuySelector,
  paymentMethodsBySourceSelector,
  hasAcceptedlegalConsentSelector,
  (
    isHybrid,
    source,
    paymentMethod,
    detail,
    hasProductsOf,
    creditLimits,
    canBuy,
    paymentMethods,
    hasAcceptedlegalConsent,
  ) => {
    if (isHybrid) {
      if (canBuy) {
        const canBuyAlicorp = canBuyByDistributor({
          source: ECustomerSource.DEX,
          method: paymentMethod.alicorpCart,
          detail: detail.alicorpCart,
          hasProducts: hasProductsOf.alicorpCart,
          paymentMethods: paymentMethods.dex,
          creditLimits,
        });

        const canBuyAllied = canBuyByDistributor({
          source: ECustomerSource.APUDEX,
          method: paymentMethod.alliedCart,
          detail: detail.alliedCart,
          hasProducts: hasProductsOf.alliedCart,
          paymentMethods: paymentMethods.apudex,
          creditLimits,
        });

        return canBuyAlicorp && canBuyAllied && hasAcceptedlegalConsent;
      }
      return false;
    }

    const canExecuteBuyOut =
      canBuy &&
      canBuyByDistributor({
        source,
        method: paymentMethod.generalCart,
        detail: detail.generalCart,
        hasProducts: hasProductsOf.generalCart,
        creditLimits,
        paymentMethods: source === ECustomerSource.DEX ? paymentMethods.dex : paymentMethods.apudex,
      }) &&
      hasAcceptedlegalConsent;

    return canExecuteBuyOut;
  },
);
