import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { addressesInitialState } from './addresses/constants';
import { IAddressesState } from './addresses/types';
import { authInitialState } from './auth/constants';
import authReducer from './auth/slice';
import { IAuthState } from './auth/types';
import creditsReducer from './credits/slice';
import { customizationInitialState } from './customization/constants';
import { ICustomizationState } from './customization/types';
import addressesReducer from './addresses';
import cartReducer, { cartInitialState, ICartState } from './cart';
import checkoutReducer, { checkoutInitialState, ICheckoutState } from './checkout';
import { creditsInitialState, ICreditsState } from './credits';
import customizationReducer from './customization';
import highlightedProductsReducer, {
  highlightedProductsInitialState,
  IHighlightedProductsState,
} from './highlighted-products';
import layoutReducer, { ILayoutState, layoutInitialState } from './layout';
import navigationReducer, { INavigationState, navigationInitialState } from './navigation';
import ordersReducer, { IOrdersState, ordersInitialState } from './orders';
import productDetailReducer, { IProductDetailState, productDetailInitialState } from './product-detail';
import suggestedProductsReducer, { ISuggestedProductsState, suggestedProductsInitialState } from './suggested-products';

export interface IState {
  auth: IAuthState;
  addresses: IAddressesState;
  checkout: ICheckoutState;
  productDetail: IProductDetailState;
  cart: ICartState;
  layout: ILayoutState;
  navigation: INavigationState;
  customization: ICustomizationState;
  orders: IOrdersState;
  credits: ICreditsState;
  suggestedProducts: ISuggestedProductsState;
  highlightedProducts: IHighlightedProductsState;
}

export const initialState: IState = {
  auth: authInitialState,
  addresses: addressesInitialState,
  checkout: checkoutInitialState,
  productDetail: productDetailInitialState,
  cart: cartInitialState,
  layout: layoutInitialState,
  navigation: navigationInitialState,
  customization: customizationInitialState,
  orders: ordersInitialState,
  credits: creditsInitialState,
  suggestedProducts: suggestedProductsInitialState,
  highlightedProducts: highlightedProductsInitialState,
};

const checkoutPersistConfig = {
  key: 'checkout',
  version: 1,
  storage,
  whitelist: ['address'],
};

export const appReducer = combineReducers({
  auth: authReducer,
  addresses: addressesReducer,
  checkout: persistReducer(checkoutPersistConfig, checkoutReducer),
  productDetail: productDetailReducer,
  cart: cartReducer,
  layout: layoutReducer,
  navigation: navigationReducer,
  customization: customizationReducer,
  orders: ordersReducer,
  credits: creditsReducer,
  suggestedProducts: suggestedProductsReducer,
  highlightedProducts: highlightedProductsReducer,
});

export const rootReducer = (state: IState, action: Action) => {
  if (action.type === 'store/reset' || action.type === 'auth/logout/fulfilled') {
    return appReducer(initialState, action);
  }
  return appReducer(state, action);
};

export type TRootState = ReturnType<typeof rootReducer>;
