import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from '../reducers';

export const getHighlightedProductsSelector = (state: TRootState) => state.highlightedProducts;

export const getFeaturedProductsSelector = createSelector(getHighlightedProductsSelector, ({ featured }) => ({
  loading: featured.loading,
  products: featured.products,
  shouldSeeMore: featured.hasMoreProducts,
  title: featured.title,
  totalProducts: featured.totalProducts,
}));
