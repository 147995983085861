import { ISuggestionSection } from 'core/model/interfaces/suggested-products.interface';
import { suggestedProductsData } from './suggested-products.data';
import { IGetSuggestionsParams } from './suggested-products.dto';
import { getSuggestionSections } from './suggested-products.mappers';

export const suggestedProductsService = {
  getSuggestions: async (params: IGetSuggestionsParams, customerId: number): Promise<Array<ISuggestionSection>> => {
    const response = await suggestedProductsData.getSuggestions(params, customerId);
    const suggestionSections = getSuggestionSections(response);
    return suggestionSections;
  },
};
