import { IBusiness } from 'core/model/interfaces/business.interface';
import { ICustomer } from 'core/model/interfaces/customer.interface';
import { customersData } from './customers.data';
import { IGetTypeDocumentsRequestParams } from './customers.dto';
import { addressesDTOtoAddressesData, toCustomers } from './customers.mappers';

export const customersService = {
  getTypeDocuments: (params: IGetTypeDocumentsRequestParams) => customersData.getTypeDocuments(params),

  getAddresses: async (): Promise<Array<IBusiness>> => {
    const data = await customersData.getAddresses();
    const addresses = addressesDTOtoAddressesData(data.externalAddresses);
    return addresses;
  },

  getProfileUser: async (customerId: number): Promise<ICustomer> => {
    const data = await customersData.getProfileUser(customerId);
    return toCustomers(data);
  },

  getCreditLimits: (customerId: number) => customersData.getCreditLimits(customerId),
};
