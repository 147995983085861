import { Icon } from '@insuma/mpp-ui/components/icon';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { useScreen } from 'core/contexts/screen.context';
import { useAddressModal } from 'core/hooks/address/use-address-selector.hooks';
import { useSelectedAddress } from 'core/hooks/address/use-selected-address.hooks';
import { accessibleOnClick } from 'shared/utils/a11y.utils';

import './banner-multiple-address.scss';

export const BannerMultipleAddress = () => {
  const css = useCSS('banner-multiple-address');
  const { addresses, setSelectorAddressOpen } = useAddressModal();
  const { selectedAddress } = useSelectedAddress();
  const { isMobile } = useScreen();
  const iconName = isMobile ? 'caret-down' : 'caret-right';

  if (addresses?.length < 2) return null;

  return (
    <div
      className={css()}
      {...accessibleOnClick(() => setSelectorAddressOpen(true))}
      aria-label="Contenedor de selector de dirección"
    >
      <div className={css('info-container')}>
        <Icon name="map-pin" size="sm" weight="fill" className={css('icon')} />
        <div className={css('text')} aria-live="polite">
          <span className={css('send-to')}>Enviar a </span>
          <span className={css('address')}>{selectedAddress?.address}</span>
        </div>
      </div>
      <Icon name={iconName} weight="bold" className={css('action')} aria-label="Abrir selector de dirección" />
    </div>
  );
};
