import { addVersionToResource } from 'shared/utils/resource-hints.utils';
import { IMAGES_ROUTES } from './general.constants';

export const ImageRoutes = {
  // CATALOGUE
  CATALOGUE_DEFAULT_PRODUCT: addVersionToResource('/assets/images/product/default-product.webp'),
  CATALOGUE_DEFAULT_PRODUCT_LARGE: addVersionToResource('/assets/images/product/default-product-large.png'),
  CATALOGUE_SEARCH_BOX: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/catalogues_search_box.svg`),
  // BENEFITS - BANNER
  BENEFITS_BANNER_MOBILE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/benefits_banner_grocer.png`),
  BENEFITS_BANNER_DESKTOP: addVersionToResource(`${IMAGES_ROUTES}/statics/images/benefits_banner_desktop.png`),
  // BENEFITS - CARDS
  BENEFITS_COBRA_NIUBIZ: addVersionToResource(`${IMAGES_ROUTES}/statics/images/benefits_cobra_niubiz.png`),
  BENEFITS_INSUMA_EDUCA: addVersionToResource(`${IMAGES_ROUTES}/statics/images/benefits_insuma_educa.png`),
  BENEFITS_MANAGE_WILLY: addVersionToResource(`${IMAGES_ROUTES}/statics/images/benefits_manage_willy.png`),
  BENEFITS_TOUCH_LOAN: addVersionToResource(`${IMAGES_ROUTES}/statics/images/benefits_touch_loan.png`),
  BENEFITS_ICON_DOLLAR: addVersionToResource(`${IMAGES_ROUTES}/statics/images/benefits_icon_dollar.png`),

  // LOGO
  LOGO_MAIN_HEADER: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/logos_main_header.svg`),
  LOGO_FOOTER: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/logos_footer.svg`),
  // LOGIN
  LOGIN_BACKGROUND: addVersionToResource(`${IMAGES_ROUTES}/statics/images/login_background.png`),
  LOGIN_LOGO: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/logos_login.svg`),
  // PASSWORD - RECOVERY
  PR_USER_VALIDATION_BANNER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/pr_user_validation_banner.png`),
  PR_USER_VALIDATION_BANNER_MOBILE: addVersionToResource(
    `${IMAGES_ROUTES}/statics/images/pr_user_validation_banner_mobile.png`,
  ),
  PR_CODE_VALIDATION_BANNER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/pr_code_validation_banner.png`),
  PR_CODE_VALIDATION_BANNER_MOBILE: addVersionToResource(
    `${IMAGES_ROUTES}/statics/images/pr_code_validation_banner_mobile.png`,
  ),
  PR_NEW_PASSWORD_BANNER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/pr_new_password_banner.png`),
  PR_NEW_PASSWORD_BANNER_MOBILE: addVersionToResource(
    `${IMAGES_ROUTES}/statics/images/pr_new_password_banner_mobile.png`,
  ),
  PR_NOT_FOUND_BANNER_MOBILE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/pr_not_found_banner_mobile.png`),
  PR_NOT_FOUND_BANNER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/pr_not_found_banner.png`),
  // EMOJIN
  EMOJIN_PARTY_POPPER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/party_popper.png`),
  EMOJIN_SMILING_FACE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/smiling_face.png`),
  EMOJIN_MOBILE_PHONE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/mobile_phone.png`),
  EMOJIN_HUGGING_FACE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/hugging_face.png`),
  EMOJIN_THUMBS_UP: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/thumbs_up.png`),
  EMOJIN_RAISING_HANDS: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/raising_hands.png`),
  EMOJIN_MILD_PANIC: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/mild_panic.png`),
  EMOJIN_FINGER_DOWN: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/finger_down.png`),
  EMOJIN_FINGER_RIGHT: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/finger_right.png`),
  EMOJIN_FINGER_UP: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/finger_up.png`),
  EMOJIN_MESSENGER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/messenger.png`),
  EMOJIN_ALERT: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/alert.png`),
  EMOJIN_HANDSHAKE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/handshake.png`),
  EMOJIN_NERVOUS: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/nervous.png`),
  EMOJIN_HANDS_UP: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/hands_up.png`),
  EMOJIN_GRIMACING_FACE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/grimacing_face.png`),
  EMOJIN_WINKING_FACE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/winking_face.png`),
  EMOJIN_MICROPHONE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/microphone.png`),
  EMOJIN_NOODLES: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/noodles.png`),
  EMOJIN_CLOCK: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/clock.png`),
  EMOJIN_CELL_LEFT_ARROW: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/cell_left_arrow.png`),
  EMOJIN_DOLLAR_FACE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/dollar_face.png`),
  EMOJIN_PRINTER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/printer.png`),
  EMOJIN_SILVER_BAG: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/silver_bag.png`),
  EMOJIN_CARD: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/card.png`),
  EMOJIN_CART: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/cart.png`),
  EMOJIN_FIRE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/emojis/fire.png`),
  ICON_YOUTUBE: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/icon_youtube.svg`),
  ICON_FACEBOOK: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/icon_facebook.svg`),
  ICON_INSTAGRAM: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/icon_instagram.svg`),
  // CHECKOUT SUCCESS
  CHECKOUT_PARTY_FACE: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/checkout_party_face.svg`),
  CHECKOUT_LOCATION: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/checkout/checkout-success_location.svg`),
  CHECKOUT_MONEY: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/checkout/checkout-success_money.svg`),
  CHECKOUT_CALENDAR: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/checkout/checkout-success_calendar.svg`),
  // PASSWORD RECOVERY
  PR_LOCK: addVersionToResource(`${IMAGES_ROUTES}/statics/images/pr_lock.png`),
  // OCTAGONS
  OCTAGONS_HIGH_FAT_TRANS: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/octagons/high_fat_trans.svg`),
  OCTAGONS_HIGH_FATS_SAT: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/octagons/high_fats_sat.svg`),
  OCTAGONS_SODIUM: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/octagons/high_sodium.svg`),
  OCTAGONS_SUGAR: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/octagons/high_sugar.svg`),
  // BANKS - QR
  BANK_BBVA: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/bbva_logo.png`),
  BANK_BCP: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/bcp_logo.png`),
  BANK_INTERBANK: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/interbank_logo.png`),
  BANK_SCOTIABANK: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/scotiabank_logo.png`),
  BANK_PLIN: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/plin.png`),
  BANK_YAPE: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/yape.png`),
  BANK_YAPE_LOGO: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/yape_logo.png`),
  BANK_YAPE_QR: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/qr/yape.png`),
  BANK_PLIN_QR: addVersionToResource(`${IMAGES_ROUTES}/statics/images/banks/qr/plin.png`),
  // PLATFORM_1
  PLATFORM_DEFAULT: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/platform/default-inactive.svg`),
  PLATFORM_PROMOTIONS: addVersionToResource(`${IMAGES_ROUTES}/statics/svgs/platform/bonificaciones-inactive.svg`),
  // SEARCH_PRODUCTS
  SEARCH_PRODUCTS_EMPTY_STATE: addVersionToResource(
    `${IMAGES_ROUTES}/statics/images/search_products/search_products_empty_state.png`,
  ),
  // PROFILE
  PROFILE_BANNER: addVersionToResource(`${IMAGES_ROUTES}/statics/images/profile/profile-banner.png`),
  // PAGE NOT FOUND
  PAGE_NOT_FOUND: addVersionToResource(`${IMAGES_ROUTES}/statics/images/page-not-found.png`),
  // PROMOTIONS
  PROMOTIONS_NOT_FOUND: addVersionToResource(`${IMAGES_ROUTES}/statics/images/promotions/promotions-not-found.jpg`),
};
