import { EServicesErrorCode } from 'core/model/enums/error-code.enum';
import { ECartError } from 'core/model/enums/shopping-cart.enum';

export const CART_VALIDATION_ERRORS_ID: Array<string> = [
  EServicesErrorCode.CART_BAD_ADDRESS_CONFIGURATION,
  EServicesErrorCode.CART_BAD_MINIMUM_SALE_QTY_CONFIGURATION,
  EServicesErrorCode.CART_NOT_VALID_VISIT_DAY,
];

export const CART_ERROR_BEHAVIOURS = {
  showErrorAndCloseCart: {
    code: ECartError.SHOW_ERROR_AND_CLOSE_CART,
    message: 'Tenemos un problema con el carrito, por favor vuelve a intentar en unos minutos.',
  },
  showErrorAndKeepCartOpen: {
    code: ECartError.SHOW_ERROR_AND_KEEP_CART_OPEN,
    message: 'Tenemos un problema con el carrito, por favor vuelve a intentar en unos minutos.',
  },
  showUserValidationError: {
    code: ECartError.SHOW_USER_VALIDATION_ERROR,
    message:
      'Información incompleta para procesar la compra, por favor comunícate con tu vendedor o escríbenos al whatsapp.',
  },
};

export const BLOCKING_PURCHASE_ERRORS: Array<string> = [
  CART_ERROR_BEHAVIOURS.showErrorAndKeepCartOpen.code,
  CART_ERROR_BEHAVIOURS.showErrorAndCloseCart.code,
];

export const NUMBER_OF_GET_CART_API_RETRIES = 5;

export const SIGNAL_ABORTED_CODE = 'SIGNAL_ABORTED';
