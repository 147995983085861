import { ECustomerSource } from 'core/model/enums/customer.enum';
import { TDistributorSource } from 'core/model/interfaces/checkout.interface';
import { IState, TRootState } from 'core/store/reducers';

export const checkoutSelector = (state: TRootState) => state.checkout;
export const selectedDeliveryDatesSelector = (state: TRootState) => state.checkout.shipping.deliveryDates;
export const checkoutShippingSelector = (state: TRootState) => state.checkout.shipping;
export const checkoutDetailSelector = (state: TRootState) => state.checkout.detail;
export const checkoutAddressSelector = (state: TRootState) => state.checkout.address;
export const checkoutCustomerSelector = (state: TRootState) => state.checkout.address.customerId;
export const paymentMethodSelector = (state: TRootState) => state.checkout.paymentMethod;
export const isSubmittingOrderSelector = (state: TRootState) => state.checkout.status.submitting;
export const isRefetchingCheckoutSelector = (state: TRootState) => state.checkout.status;
export const isOrderCompletedSelector = (state: TRootState) => state.checkout.status.completed;
export const checkoutTotalAmountsSelector = (state: TRootState) => state.checkout.detail.amounts;
export const checkoutGlobalErrorSelector = (state: TRootState) => state.checkout.error.global;
export const orderSuccessDetailSelector = (state: TRootState) => state.checkout.orderSuccessDetail;
export const isCheckoutLoadingDetailSelector = (state: TRootState) => state.checkout.loading.global;
export const loadingSelector = (state: TRootState) => state.checkout.loading;
export const errorSelector = (state: TRootState) => state.checkout.error;
export const selectCheckoutAgreements = (state: TRootState) => state.checkout.agreements;

export const sourceInViewSelector = (_state: IState, sourceInView?: ECustomerSource) => sourceInView;
export const sourceNameSelector = (_state: IState, sourceName: TDistributorSource) => sourceName;
