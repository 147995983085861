import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios';
import { specUrlErrorAvoidRefresh } from 'core/constants/error-http.constants';
import { responsesTokenExpired } from 'core/constants/http.constants';
import { DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS } from 'core/constants/msal.constants';
import { IServiceError } from 'core/model/interfaces/error.interface';
import msalPublicClientApplication from 'shared/utils/msal.utils';
import { extractSearchParams } from 'shared/utils/url.utils';
import { handleCommonErrors } from './http-utils';

interface ITokenData {
  idToken: string;
  refreshToken: string;
  accessToken: string;
}

interface ICustomOptions {
  attachTokenToRequest?: (request: AxiosRequestConfig, token: string) => void;
  handleTokenRefresh?: () => Promise<ITokenData>;
  setTokenData?: (tokenData: ITokenData) => Promise<void>;
  shouldInterceptTokenExp?: (error: AxiosError<IServiceError>) => boolean;
}

const isUnauthenticatedError = (error: AxiosError<IServiceError>) =>
  error.response?.status === HttpStatusCode.Unauthorized;

const shouldInterceptTokenExp = (error: AxiosError<IServiceError>) =>
  error?.response?.status === 401 &&
  responsesTokenExpired.includes(error?.response?.data.error.message) &&
  !specUrlErrorAvoidRefresh.some(urlRegex => new RegExp(urlRegex).test(error?.response?.config?.url ?? ''));

const attachTokenToRequest = (request: AxiosRequestConfig, token: string, accessToken?: string): AxiosRequestConfig => {
  request.headers = request.headers ?? {};
  request.headers.Authorization = `Bearer ${token}`;

  if (request?.data) {
    const newData = JSON.parse(request?.data);
    if (newData?.accessToken) {
      newData.accessToken = accessToken;
      request.data = JSON.stringify(newData);
    }
  }
  return request;
};

const createAuthenticatedResInterceptor = (
  axiosClient: AxiosInstance,
  interceptorSuccess: (value: AxiosResponse<any>) => AxiosResponse<any>,
  customOptions: ICustomOptions = {},
) => {
  const options = {
    attachTokenToRequest,
    shouldInterceptTokenExp,
    ...customOptions,
  };

  const interceptorError = (error: AxiosError<IServiceError>) => {
    if (isUnauthenticatedError(error)) {
      const searchParams = extractSearchParams();
      msalPublicClientApplication.loginRedirect({
        ...DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS,
        extraQueryParameters: {
          ...searchParams,
          ...DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS.extraQueryParameters,
        },
      });
      return Promise.reject(error);
    }

    if (!options.shouldInterceptTokenExp(error)) {
      handleCommonErrors(error);
      return Promise.reject(error);
    }
  };

  axiosClient.interceptors.response.use(interceptorSuccess, interceptorError);
};

export default createAuthenticatedResInterceptor;
