import { navigationService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { INavigationState } from '../types';

export const getNavigationTreeAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/getNavigationTree`,
  (_, { getState }) => {
    const state = getState();
    const { checkout } = state;
    const customerId = getCustomerIdFromCheckout(checkout);

    return navigationService.getNavigationTree(customerId);
  },
);

export const getNavigationTreeReducer: TSliceExtraReducer<INavigationState> = builder => {
  builder
    .addCase(getNavigationTreeAction.pending, state => {
      state.categories.loading = true;
      state.categories.error = null;
    })
    .addCase(getNavigationTreeAction.fulfilled, (state, { payload }) => {
      state.categories.list = payload.categories;
      state.menuLinks.header = payload.header;
      state.menuLinks.sidebar = payload.sidebar;
      state.menuLinks.footer = payload.footer;
      state.categories.idle = false;
      state.categories.loading = false;
      state.categories.error = null;
    })
    .addCase(getNavigationTreeAction.rejected, (state, { error }) => {
      state.categories.idle = true;
      state.categories.loading = false;
      state.categories.error = error;
    });

  return builder;
};
