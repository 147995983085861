import { ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RollbackInternalError } from 'screens/errors/rollback-internal-error';
import { SuspensePageLoading } from '../suspense-page-loading';

interface ISuspensePageProps {
  children: ReactNode;
}

export const SuspensePage = ({ children }: ISuspensePageProps) => (
  <ErrorBoundary FallbackComponent={RollbackInternalError}>
    <Suspense fallback={<SuspensePageLoading />}>{children}</Suspense>
  </ErrorBoundary>
);
