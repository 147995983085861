export enum EInsiderPageType {
  HOME = 'Home',
  PRODUCT = 'Product',
  CATEGORY = 'Category',
  SEARCH = 'Search',
  BASKET = 'Basket',
  CHECKOUT = 'Checkout',
  CONFIRMATION = 'Confirmation',
  CONTENT = 'Content',
}
