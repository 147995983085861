import { IBusiness } from 'core/model/interfaces/business.interface';
import type { TSliceReducer } from 'core/store';
import { ICheckoutState, ISetDeliveryDatePayload, ISetPaymentMethodPayload } from './types';

export const setAddress: TSliceReducer<ICheckoutState, IBusiness> = (state, { payload }) => {
  state.address = payload;
};

export const setDeliveryDate: TSliceReducer<ICheckoutState, ISetDeliveryDatePayload> = (state, { payload }) => {
  const { groupingRule, date, distributor } = payload;
  state.shipping.deliveryDates[distributor][groupingRule] = date;
};

export const setPaymentMethod: TSliceReducer<ICheckoutState, ISetPaymentMethodPayload> = (state, { payload }) => {
  if (payload.distributor) {
    state.paymentMethod[payload.distributor] = payload.method;
  }
};

export const resetIdleShipping: TSliceReducer<ICheckoutState> = state => {
  state.shipping.idle = true;
};

const updateLegalConsent: TSliceReducer<ICheckoutState, boolean> = (state, action) => {
  state.agreements.legalConsent = action.payload;
};

const updateDataPrivacyConsent: TSliceReducer<ICheckoutState, boolean> = (state, action) => {
  state.agreements.dataPrivacyConsent = action.payload;
};

export const resetCheckoutError: TSliceReducer<ICheckoutState, keyof ICheckoutState['error']> = (
  state,
  { payload },
) => {
  state.error[payload] = null;
};

export const reducers = {
  resetIdleShipping,
  setAddress,
  setPaymentMethod,
  setDeliveryDate,
  updateLegalConsent,
  updateDataPrivacyConsent,
  resetCheckoutError,
};
