import { EPromotionDetailTag, EPromotionTag } from 'core/model/enums/promotion.enum';
import { IPromoIndicators, IUnitMeasure, IUnitMeasureDetail } from 'core/model/interfaces/product.interface';
import { IDetailedPromotion, ITierPromotionDetail } from 'core/model/interfaces/promotion.interface';
import { IPromotionsDTO, IUnitMeasureDetailDTO, IUnitMeasureDTO } from './catalogs.dto';
import { IDetailedPromotionDto, ITieredPromotionDto } from './catalogs-promotions.dto';

export const getPromoIndicatorsForUnitMeasureDTO = (promotions: IPromotionsDTO): IPromoIndicators => {
  const { types, hasExclusive: isExclusive } = promotions;
  let hasDiscount = false;
  let hasCombo = false;
  let hasBonus = false;
  let maxDiscount = 0;

  for (let i = 0; i < types.length; i += 1) {
    if (hasDiscount && hasCombo && hasBonus) {
      break;
    }
    const promotion = types[i];
    switch (promotion.tag) {
      case EPromotionTag.SIMPLE_DISCOUNT:
        hasDiscount = true;
        break;
      case EPromotionTag.TIERED_DISCOUNT:
        maxDiscount = promotion.maxDiscount ?? 0;
        hasDiscount = true;
        break;
      case EPromotionTag.COMBO_BONUS:
      case EPromotionTag.COMBO_DISCOUNT:
        hasCombo = true;
        break;
      case EPromotionTag.TIERED_BONUS:
      case EPromotionTag.SIMPLE_BONUS:
        hasBonus = true;
        break;
      default:
        break;
    }
  }
  return {
    hasDiscount,
    hasCombo,
    hasBonus,
    maxDiscount,
    isExclusive,
  };
};

export const getPromoIndicatorsForUnitMeasureDetailDTO = (promotions: IDetailedPromotionDto): IPromoIndicators => {
  const { types, hasExclusive: isExclusive } = promotions;
  const { simple = [], tiered = [], combo = [] } = types;
  let hasSimpleDiscount = false;
  let hasTieredDiscount = false;
  let hasSimpleBonus = false;
  let hasTieredBonus = false;
  let hasComboBonus = false;
  let hasComboDiscount = false;

  simple.some(simplePromo => {
    hasSimpleDiscount = simplePromo.type === EPromotionDetailTag.DISCOUNT;
    hasSimpleBonus = simplePromo.type === EPromotionDetailTag.BONUS;
    return hasSimpleDiscount && hasSimpleBonus;
  });
  tiered.some(tieredPromo => {
    hasTieredDiscount = tieredPromo.type === EPromotionDetailTag.DISCOUNT;
    hasTieredBonus = tieredPromo.type === EPromotionDetailTag.BONUS;
    return hasTieredDiscount && hasTieredBonus;
  });
  combo.some(tieredPromo => {
    hasComboDiscount = tieredPromo.type === EPromotionDetailTag.DISCOUNT;
    hasComboBonus = tieredPromo.type === EPromotionDetailTag.BONUS;
    return hasComboDiscount && hasComboBonus;
  });
  const hasDiscount = hasSimpleDiscount || hasTieredDiscount;
  const hasBonus = hasSimpleBonus || hasTieredBonus;
  const hasCombo = hasComboBonus || hasComboDiscount;
  const maxDiscount = getMaxDiscount(tiered);

  return {
    hasDiscount,
    hasCombo,
    hasBonus,
    maxDiscount,
    isExclusive,
  };
};

export const getMaxDiscount = (tieredPromos: Array<ITieredPromotionDto>): number => {
  let maxDiscount = 0;
  tieredPromos.forEach(promo => {
    const lastTierInCurrentPromo = promo.limits[promo.limits.length - 1];
    if (lastTierInCurrentPromo.discount && lastTierInCurrentPromo.discount > maxDiscount) {
      maxDiscount = lastTierInCurrentPromo.discount;
    }
  });
  return maxDiscount;
};

export const buildDetailedPromotions = (data: IDetailedPromotionDto): IDetailedPromotion => {
  const promotions: IDetailedPromotion = {
    simple: [],
    tiered: [],
    combo: [],
  };
  const { types } = data;
  const { simple = [], tiered = [], combo = [] } = types;
  promotions.simple = simple.map(simplePromotion => ({
    tag: simplePromotion.type,
    id: simplePromotion.id,
  }));

  promotions.tiered = tiered.map(tieredPromotion => {
    const tiers: Array<ITierPromotionDetail> = [];
    tieredPromotion.limits.forEach(limit => {
      const { discount, lower, upper, bonus } = limit;

      tiers.push({
        maxQuantity: upper,
        minQuantity: lower,
        discount,
        bonus,
      });
    });

    return {
      id: tieredPromotion.id,
      limitType: tieredPromotion.limitType,
      tiers,
      tag: tieredPromotion.type,
    };
  });

  promotions.combo = combo.map(comboPromotion => ({
    tag: comboPromotion.type,
    description: comboPromotion.description,
    id: comboPromotion.id,
  }));
  return promotions;
};

export const getUnitMeasureDetailFromDTO = (unitMeasure: IUnitMeasureDetailDTO): IUnitMeasureDetail => {
  const { hasCombo, maxDiscount, hasBonus, hasDiscount, isExclusive } = getPromoIndicatorsForUnitMeasureDetailDTO(
    unitMeasure.promotions,
  );

  return {
    selected: unitMeasure.default,
    code: unitMeasure.code,
    default: unitMeasure.default,
    name: unitMeasure.description,
    presentation: unitMeasure.presentation,
    conversionFactor: unitMeasure.conversionFactor,
    price: unitMeasure.price,
    originalPrice: unitMeasure.originalPrice,
    minimumSaleQuantity: unitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: unitMeasure.maximumSaleQuantity,
    promoIndicators: { maxDiscount, hasCombo, hasBonus, hasDiscount, isExclusive },
    promotions: buildDetailedPromotions(unitMeasure.promotions),
  };
};

export const getUnitMeasureFromDTO = (unitMeasure: IUnitMeasureDTO): IUnitMeasure => {
  const { hasCombo, maxDiscount, hasBonus, hasDiscount, isExclusive } = getPromoIndicatorsForUnitMeasureDTO(
    unitMeasure.promotions,
  );

  return {
    selected: unitMeasure.default,
    code: unitMeasure.code,
    default: unitMeasure.default,
    name: unitMeasure.description,
    presentation: unitMeasure.presentation,
    conversionFactor: unitMeasure.conversionFactor,
    price: unitMeasure.price,
    originalPrice: unitMeasure.originalPrice,
    minimumSaleQuantity: unitMeasure.minimumSaleQuantity,
    maximumSaleQuantity: unitMeasure.maximumSaleQuantity,
    promoIndicators: { maxDiscount, hasBonus, hasDiscount, hasCombo, isExclusive },
    promotions: unitMeasure.promotions.types,
  };
};

export const getActiveUnitMeasuresDTO = <T extends IUnitMeasureDTO | IUnitMeasureDetailDTO>(
  unitMeasures: Array<T>,
  sku: string,
) => {
  const activeUnitMeasures = unitMeasures.filter(um => um.isActive === true);
  if (activeUnitMeasures.length < 1) throw new Error(`There are no active unit measures for sku: ${sku}`);
  return activeUnitMeasures;
};
