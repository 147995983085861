import { Skeleton } from '@insuma/mpp-ui/components/skeleton';
import { Arrow } from '../arrow';
import { SliderDots } from '../slider-dots';

import '../../slider.scss';

export const SliderLoading = () => (
  <div className="slider" role="alert" aria-label="cargando sliders" aria-busy="true" aria-live="polite">
    <div className="banners">
      <Skeleton className="slider__slide-loading" />
      <Arrow direction="left" />
      <Arrow direction="right" />
    </div>
    <SliderDots slides={[{ imageUrl: '', redirectionUrl: '' }]} activeSlide={0} />
  </div>
);
