import { addressesDataSelector } from 'core/store/addresses/selectors';
import { toogleAddressModal } from 'core/store/layout';
import { addressModalLayoutSelector } from 'core/store/layout/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';

export const useAddressModal = () => {
  const isSelectorAddressOpen = useAppSelector(addressModalLayoutSelector);
  const addresses = useAppSelector(addressesDataSelector);

  const dispatch = useAppDispatch();

  const setSelectorAddressOpen = (state: boolean) => {
    dispatch(toogleAddressModal(state));
  };

  return { isSelectorAddressOpen, setSelectorAddressOpen, addresses };
};
