import type { TSliceExtraReducer } from 'core/store';
import { authGetCustomerSource } from 'core/store/auth/selectors';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { buildOptimisticCartByGroups, isProductAlreadyInCart } from '../cart-state.utils';
import { SLICE_NAMESPACE } from '../constants';
import { ICartState, IChangeProductActionParams } from '../types';

export const buildOptimisticCartAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/buildOptimisticCartAction`,
  async (payload: IChangeProductActionParams, { getState, dispatch }) => {
    const state = getState();
    const { cart } = state;
    const { itemsGroups } = cart;
    const customerSource = dispatch(() => authGetCustomerSource(state));
    const hasAlreadyAdded = isProductAlreadyInCart(customerSource, itemsGroups, payload.sku);
    return {
      cart: { ...cart, itemsGroups: buildOptimisticCartByGroups(customerSource, hasAlreadyAdded, cart, payload) },
    };
  },
);

export const buildOptimisticCartReducer: TSliceExtraReducer<ICartState> = builder => {
  builder.addCase(buildOptimisticCartAction.fulfilled, (state, { payload }) => {
    state.itemsGroups = payload.cart.itemsGroups;
    state.deletedProducts = payload.cart.deletedProducts;
    state.amounts = payload.cart.amounts;
  });

  return builder;
};
