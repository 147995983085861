import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { notification } from '@insuma/mpp-ui/notifications';
import { DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS } from 'core/constants/msal.constants';
import { Path } from 'core/constants/path.constants';
import { Loading } from 'shared/components/loading';

import './index-page.scss';

export const IndexPage = () => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const isLoggedIn = useIsAuthenticated();
  const pathToRedirect = `${Path.HOME}${search}`;
  const searchParamsObject = Object.fromEntries([...searchParams]);

  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isLoggedIn) {
    try {
      instance.loginRedirect({
        ...DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS,
        prompt: 'login',
        extraQueryParameters: {
          ...searchParamsObject,
          ...DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS.extraQueryParameters,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('login error', error);
      notification.error(`Ocurrió un error inesperado durante el inicio de sesión`);
    }
  }

  if (isLoggedIn) {
    return <Navigate to={pathToRedirect} replace />;
  }

  return (
    <div className="index-page">
      <Loading />
    </div>
  );
};
