/* eslint-disable no-console */
import { NavigateFunction } from 'react-router-dom';
import {
  Configuration,
  LogLevel,
  NavigationClient,
  NavigationOptions,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MSAL_BASE_URL,
  MSAL_CLIENT_ID,
  MSAL_POSTLOGOUT_REDIRECTION_URI,
  MSAL_REDIRECTION_URI,
  MSAL_TENANT_NAME,
} from 'core/constants/general.constants';
import { isProduction } from './env.utils';

export class CustomNavigationClient extends NavigationClient {
  private navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}

const config: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: `https://${MSAL_BASE_URL}/${MSAL_TENANT_NAME}.onmicrosoft.com/b2c_1a_insuma_signin`,
    knownAuthorities: [`${MSAL_BASE_URL}`],
    redirectUri: MSAL_REDIRECTION_URI,
    postLogoutRedirectUri: MSAL_POSTLOGOUT_REDIRECTION_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii || isProduction()) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};

const msalPublicClientApplication = new PublicClientApplication(config);

export default msalPublicClientApplication;
