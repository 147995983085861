export const AVOID_SHOWING_ERRORS_CODES: Array<string> = [
  'PRODUCT_WITHOUT_STOCK',
  'PASSWORDS_CONFLICT',
  'INVALID_USERNAME_PASSWORD',
  'ENGINE_DELIVERY_DATE_VALIDATION_ERROR',
];

export const dictionaryMessagesCodesError: { [key: string]: string } = {
  PRODUCT_WITHOUT_STOCK: 'PRODUCT_WITHOUT_STOCK',
  PASSWORDS_CONFLICT: 'PASSWORDS_CONFLICT',
  INVALID_USERNAME_PASSWORD: 'INVALID_USERNAME_PASSWORD',
  ENGINE_DELIVERY_DATE_VALIDATION_ERROR: 'ENGINE_DELIVERY_DATE_VALIDATION_ERROR',
};

export const AVOID_SHOWING_ERRORS_ENDPOINTS = [
  { url: '/gtm-order-management/v1/cart', method: 'get' },
  { url: '/gtm-order-management/v1/orders', method: 'post' },
];

export const specUrlErrorAvoidRefresh = ['/iam/v1/signOut'];

export const UNEXPECTED_ERROR_MESSAGE = 'Ha ocurrido un error inesperado';
export const GENERAL_ERROR_CODE = 'GENERAL_ERROR';
export const UNEXPECTED_ERROR_CODE = 'UNEXPECTED_ERROR';

export enum EHTTPErrorCode {
  SERVER_ERROR = 500,
  UNAUTHORIZED = 401,
}
export const CLIENT_OFFLINE_NETWORK_ERROR_CODE = 'ERR_NETWORK';
export const TIMEOUT_NETWORK_ERROR_CODE = 'ECONNABORTED';
