import { useLocation } from 'react-router-dom';
import { useScreen } from 'core/contexts/screen.context';
import { notFoundLayoutSelector } from 'core/store/layout/selectors';
import { useAppSelector } from 'core/store/store.utils';

interface IMobileRouteVisibilityProps {
  blacklist: Array<string>;
  children: JSX.Element;
}

export const MobileLayoutVisibility = ({ blacklist, children }: IMobileRouteVisibilityProps) => {
  const { pathname } = useLocation();
  const { isMobile } = useScreen();
  const is404 = useAppSelector(notFoundLayoutSelector);

  if (is404 || (blacklist.includes(pathname) && isMobile)) return null;
  return children;
};
